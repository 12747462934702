const toggleMenu = 'TOGGLE_MENU';
const initialState = {
    smallMenu: false,
    visible: false
};

export const actionCreators = {
    toggleSideMenu: () => ({type: toggleMenu}),
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === toggleMenu) {
        return {...state, visible: !state.visible};
    }

    return state;
};
