import React from 'react'
import {Dimmer, Grid, GridColumn, Image, Loader} from 'semantic-ui-react'

const AppLoader = () => {

    return (
        <Grid stackable textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <GridColumn style={{width: 455}}>
                <Dimmer active inverted>
                    <Loader inverted />
                </Dimmer>

                <Image src='/images/short-paragraph.png' size="medium" className="t-center"/>

            </GridColumn>
        </Grid>
    );
};

export default AppLoader

