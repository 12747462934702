import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/banks/',
        'Bank',
        'Banks',
        initialState);

export const bankConstants = constants;
export const bankActions = actions;
export const bankReducer = reducer;
