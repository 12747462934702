import axios from './Axios';

const initialState = { items: []};

const registrationStatusConstants = {
    GET_REGISTRATIONSTATUSES: 'GET_REGISTRATIONSTATUSES',
    GET_REGISTRATIONSTATUSES_SUCCESS: 'GET_REGISTRATIONSTATUSES_SUCCESS',
    GET_REGISTRATIONSTATUSES_FAILURE: 'GET_REGISTRATIONSTATUSES_FAILURE',
};

export const registrationStatusActions = {
    /* get */
    getRegistrationStatuses: () => {
        return (dispatch) => {

            axios.get('/main/registration-statuses/')
                .then((response) => dispatch(registrationStatusActions.getRegistrationStatusesSuccess(response)))
                .catch((response) => dispatch(registrationStatusActions.getRegistrationStatusesFailure(response)));

            return {
                type: registrationStatusConstants.GET_REGISTRATIONSTATUSES,
                payload: {
                    loading: true
                }
            };
        };
    },

    getRegistrationStatusesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: registrationStatusConstants.GET_REGISTRATIONSTATUSES_SUCCESS,
                payload: {
                    items: response.items,
                    loading: false
                }
            });
        };
    },

    getRegistrationStatusesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: registrationStatusConstants.GET_REGISTRATIONSTATUSES_FAILURE,
                payload: {
                    error: response,
                    loading: false
                }
            });

        };
    },
};

export function registrationStatusReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case registrationStatusConstants.GET_REGISTRATIONSTATUSES:
            return {
                ...state,
                loading: action.payload.loading
            };
        case registrationStatusConstants.GET_REGISTRATIONSTATUSES_SUCCESS:
            return {
                ...state,
                items: action.payload.items,
                loading: action.payload.loading
            };
        case registrationStatusConstants.GET_REGISTRATIONSTATUSES_FAILURE:
            return {
                ...state,
                items: [],
                error: action.payload.error,
                loading: action.payload.loading
            };
        default:
            return state
    }
}