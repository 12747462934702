import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/economic-sectors/',
        'EconomicSector',
        'EconomicSectors',
        initialState);

export const economicSectorConstants = constants;
export const economicSectorActions = actions;
export const economicSectorReducer = reducer;
