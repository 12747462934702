import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/cities/',
        'City',
        'Cities',
        initialState);

export const cityConstants = constants;
export const cityActions = actions;
export const cityReducer = reducer;