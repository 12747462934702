import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/sector-subsectors/',
        'SubSector',
        'SubSectors',
        initialState);

export const industrySubSectorConstants = constants;
export const industrySubSectorActions = actions;
export const industrySubSectorReducer = reducer;
