import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/municipalities/',
        'Municipality',
        'Municipalities',
        initialState);

export const municipalityConstants = constants;
export const municipalityActions = actions;
export const municipalityReducer = reducer;