import axios from './Axios';

const initialState = { items: []};

const faqConstants = {
    GET_FAQ: 'GET_FAQ',
    GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
    GET_FAQ_FAILURE: 'GET_FAQ_FAILURE',
};

export const faqActions = {
    /* get */
    getFaqs: (data) => {
        return (dispatch) => {

            dispatch({
                type: faqConstants.GET_FAQ,
                payload: {
                    loading: true
                }
            });

            return axios.get('/main/faqs/', { params: data })
                .then(
                    (response) => dispatch(faqActions.getFaqsSuccess(response)),
                    (response) => dispatch(faqActions.getFaqsFailure(response)));

        };
    },

    getFaqsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: faqConstants.GET_FAQ_SUCCESS,
                payload: {
                    ...response,
                    loading: false
                }
            });
        };
    },

    getFaqsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: faqConstants.GET_FAQ_FAILURE,
                payload: {
                    items: [],
                    error: response,
                    loading: false
                }
            });

        };
    },
};

export function faqReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case faqConstants.GET_FAQ:
            return {
                ...state,
                loading: true
            };
        case faqConstants.GET_FAQ_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case faqConstants.GET_FAQ_FAILURE:
            return {
                ...state,
                items: [],
                error: action.payload.error,
                loading: action.payload.loading
            };
        default:
            return state
    }
}
