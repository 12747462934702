import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/company-director/',
        'CompanyDirector',
        'CompanyDirectors',
        initialState);

export const companyDirectorConstants = constants;
export const companyDirectorActions = actions;
export const companyDirectorReducer = reducer;

