import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/companies/',
        'Company',
        'Companies',
        initialState);

export const companyConstants = constants;
export const companyActions = actions;
export const companyReducer = reducer;
