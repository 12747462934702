import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/metabase-report/',
        'ReportUrl',
        'ReportUrls',
        initialState);

export const reportUrlConstants = constants;
export const reportUrlActions = actions;
export const reportUrlReducer = reducer;
