
// cache it to load once
var roles = {};
var roles_permissions = {};

export const initializePermissions = function(force) {

    if ( force ) {
        roles = {};
        roles_permissions = {};
    }

    if (localStorage.getItem('role') && !roles.__loaded) {
        let __roles = localStorage.getItem('role').split(',');
        __roles && __roles.map((item) => {
            if (!roles.hasOwnProperty(item)) {
                roles[item] = true;
            }
            return item;
        });
        roles.__loaded = true;
        // console.log('loaded roles', roles);
    }

    if (localStorage.getItem('roles_permissions') && !roles_permissions.__loaded) {
        let _roles_permissions = JSON.parse(localStorage.getItem('roles_permissions'));
        _roles_permissions.map((item) => {
            let r, p;
            if (item.hasOwnProperty('alias')) {
                r = item['alias'];
                p = item['pattern'];
            } else
                [r, p] = item;

            roles[r] = true;
            roles_permissions[p] = true;

            return item;
        });
        roles_permissions.__loaded = true;
        // console.log('loaded roles_permissions', roles_permissions);
    }
}

const hasPermission = function(permission, strict = false) {
    initializePermissions();

    // console.log(roles);

    if (strict === false) {

        // TODO: remove this from here, and give super-admin permissions from backend
        if (roles['super-admin']) {
            return true;
        }

    }
    return (roles.hasOwnProperty(permission) && roles[permission]) ||
           (roles_permissions.hasOwnProperty(permission) && roles_permissions[permission] );
};

export const hasPermissionOneOf = function(permissions, strict = false) {
    // console.log('has one of', permissions);
    for (let id in permissions) {
        if ( permissions.hasOwnProperty(id)) {
            if (hasPermission(permissions[id], strict))
                return true;
        }
    }
};

export default hasPermission;
