import axios from './Axios';

const initialState = { items: []};

const enterpriseSizeConstants = {
    GET_ENTERPRISESIZE: 'GET_ENTERPRISESIZE',
    GET_ENTERPRISESIZE_SUCCESS: 'GET_ENTERPRISESIZE_SUCCESS',
    GET_ENTERPRISESIZE_FAILURE: 'GET_ENTERPRISESIZE_FAILURE',

    UPDATE_ENTERPRISE_SIZE: 'UPDATE_ENTERPRISE_SIZE',
    UPDATE_ENTERPRISE_SIZE_SUCCESS: 'UPDATE_ENTERPRISE_SIZE_SUCCESS',
    UPDATE_ENTERPRISE_SIZE_FAILURE: 'UPDATE_ENTERPRISE_SIZE_FAILURE',
};

export const enterpriseSizeActions = {
    /* get */
    getEnterpriseSizes: () => {
        return (dispatch) => {

            axios.get('/main/enterprise-sizes/')
                .then((response) => dispatch(enterpriseSizeActions.getEnterpriseSizesSuccess(response)))
                .catch((response) => dispatch(enterpriseSizeActions.getEnterpriseSizesFailure(response)));

            return {
                type: enterpriseSizeConstants.GET_ENTERPRISESIZE,
                payload: {
                    loading: true
                }
            };
        };
    },

    getEnterpriseSizesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: enterpriseSizeConstants.GET_ENTERPRISESIZE_SUCCESS,
                payload: {
                    items: response.items,
                    loading: false
                }
            });
        };
    },

    getEnterpriseSizesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: enterpriseSizeConstants.GET_ENTERPRISESIZE_FAILURE,
                payload: {
                    items: [],
                    error: response,
                    loading: false
                }
            });

        };
    },

    /* update */
    updateEnterpriseSize: (companyId, enterpriseSizeId) => {
        return (dispatch) => {
            dispatch({ type: enterpriseSizeConstants.UPDATE_ENTERPRISE_SIZE });

            return axios.put(`/main/update_enterprise_size/${companyId}`, { enterprise_size_id: enterpriseSizeId })
                .then(response => {
                    dispatch(enterpriseSizeActions.updateEnterpriseSizeSuccess(response.data));
                    return response.data;
                })
                .catch(error => {
                    dispatch(enterpriseSizeActions.updateEnterpriseSizeFailure(error));
                    throw error;
                });
        };
    },
    updateEnterpriseSizeSuccess: response => {
        return { type: enterpriseSizeConstants.UPDATE_ENTERPRISE_SIZE_SUCCESS, payload: response };
    },
    updateEnterpriseSizeFailure: error => {
        return { type: enterpriseSizeConstants.UPDATE_ENTERPRISE_SIZE_FAILURE, payload: { error } };
    }
};

export function enterpriseSizeReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case enterpriseSizeConstants.GET_ENTERPRISESIZE:
            return {
                ...state,
                loading: action.payload.loading
            };
        case enterpriseSizeConstants.GET_ENTERPRISESIZE_SUCCESS:
            return {
                ...state,
                items: action.payload.items,
                loading: action.payload.loading
            };
        case enterpriseSizeConstants.GET_ENTERPRISESIZE_FAILURE:
            return {
                ...state,
                items: [],
                error: action.payload.error,
                loading: action.payload.loading
            };
        /* update */
        case enterpriseSizeConstants.UPDATE_ENTERPRISE_SIZE:
            return {
                ...state,
                loading: true
            };
        case enterpriseSizeConstants.UPDATE_ENTERPRISE_SIZE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case enterpriseSizeConstants.UPDATE_ENTERPRISE_SIZE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        default:
            return state
    }
}
