import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/esd/statistics/',
        'EsdStatistics',
        'EsdStatistics',
        initialState,
        ['get']);

export const esdStatisticsConstants = constants;
export const esdStatisticsActions = actions;
export const esdStatisticsReducer = reducer;
