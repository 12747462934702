import axios from './Axios';

const initialState = { provinces: []};

const provinceConstants = {
    GET_PROVINCES: 'GET_PROVINCES',
    GET_PROVINCES_SUCCESS: 'GET_PROVINCES_SUCCESS',
    GET_PROVINCES_FAILURE: 'GET_PROVINCES_FAILURE',
};

export const provinceActions = {
    /* get */
    getProvinces: (params) => {
        return (dispatch) => {

            dispatch({
                type: provinceConstants.GET_PROVINCES,
            });

            return axios.get('/main/provinces/', {params})
                .then((response) => dispatch(provinceActions.getProvincesSuccess(response)),
                    (response) => dispatch(provinceActions.getProvincesFailure(response)));

        };
    },

    getProvincesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: provinceConstants.GET_PROVINCES_SUCCESS,
                payload: {
                    provinces: response.items
                }
            });
        };
    },

    getProvincesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: provinceConstants.GET_PROVINCES_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function provinceReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case provinceConstants.GET_PROVINCES:
            return {
                ...state
            };
        case provinceConstants.GET_PROVINCES_SUCCESS:
            return {
                ...state,
                provinces: action.payload.provinces
            };
        case provinceConstants.GET_PROVINCES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
