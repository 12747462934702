import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/industry-groups/',
        'IndustryGroup',
        'IndustryGroups',
        initialState);

export const industryGroupConstants = constants;
export const industryGroupActions = actions;
export const industryGroupReducer = reducer;
