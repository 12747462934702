import axios from './Axios';
import qs from 'qs';

const initialState = {subSectors: []};

const subSectorsConstants = {
    GET_SUB_SECTORS: 'GET_SUB_SECTORS',
    GET_SUB_SECTORS_SUCCESS: 'GET_SUB_SECTORS_SUCCESS',
    GET_SUB_SECTORS_FAILURE: 'GET_SUB_SECTORS_FAILURE',
};

export const subSectorActions = {
    /* get */
    getSubSectors: (params) => {
        return (dispatch) => {

            dispatch({type: subSectorsConstants.GET_SUB_SECTORS})


            return axios.get('/main/sector-subsectors/', {params})
                .then((response) => {
                        dispatch(subSectorActions.getSubSectorsSuccess(response))
                        return response
                    },
                    (response) => dispatch(subSectorActions.getSubSectorsFailure(response)));
        };
    },


    getSubSectorsMulti: (params) => {
        return (dispatch) => {

            dispatch({type: subSectorsConstants.GET_SUB_SECTORS})


            return axios.get('/main/sector-subsectors/', {params, paramsSerializer: params => { 
                let query = qs.stringify(params, {arrayFormat: 'comma', encode: false})
                query = decodeURIComponent(query)
                return query
            }})
                .then((response) => {
                        dispatch(subSectorActions.getSubSectorsSuccess(response))
                        return response
                    },
                    (response) => dispatch(subSectorActions.getSubSectorsFailure(response)));
        };
    },

    getSubSectorsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: subSectorsConstants.GET_SUB_SECTORS_SUCCESS,
                payload: {
                    subSectors: response.items
                }
            });
        };
    },

    getSubSectorsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: subSectorsConstants.GET_SUB_SECTORS_FAILURE,
                payload: {error: response}
            });

        };
    },

    resetSubSectors: () => {
        return {
            type: subSectorsConstants.GET_SUB_SECTORS_SUCCESS,
            payload: {
                subSectors: []
            }
        }
    },


    getSubSector: (id) => {
        return (dispatch) => {

            return axios.get('/main/sector-subsectors/' + id)
                .then((response) => response,
                    (response) => dispatch(subSectorActions.getSubSectorsFailure(response)));

        };
    },
};

export function subSectorReducer(state = initialState, action) {
    switch (action.type) {
        /* get */
        case subSectorsConstants.GET_SUB_SECTORS:
            return {
                ...state
            };
        case subSectorsConstants.GET_SUB_SECTORS_SUCCESS:
            return {
                ...state,
                subSectors: action.payload.subSectors
            };
        case subSectorsConstants.GET_SUB_SECTORS_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
