import axios from './Axios';

const initialState = { missing: [], types: new Set()};

const promisedDocumentsConstants = {
    GET_MISSING_DOCUMENTS: 'GET_MISSING_DOCUMENTS',
    GET_MISSING_DOCUMENTS_SUCCESS: 'GET_MISSING_DOCUMENTS_SUCCESS',
    GET_MISSING_DOCUMENTS_FAILURE: 'GET_MISSING_DOCUMENTS_FAILURE',
};

export const promisedDocumentsActions = {
    /* get */
    getMissingDocuments: (smid) => {
        return (dispatch) => {

            axios.get(`/main/missing_promised_uploads/${smid}`)
                .then((response) => dispatch(promisedDocumentsActions.getMissingDocumentsSuccess(response)))
                .catch((response) => dispatch(promisedDocumentsActions.getMissingDocumentsFailure(response)));

            return {
                type: promisedDocumentsConstants.GET_MISSING_DOCUMENTS,
                payload: {
                    loading: true
                }
            };
        };
    },

    getMissingDocumentsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: promisedDocumentsConstants.GET_MISSING_DOCUMENTS_SUCCESS,
                payload: {
                    missing: response.missing,
                    loading: false
                }
            });
        };
    },

    getMissingDocumentsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: promisedDocumentsConstants.GET_MISSING_DOCUMENTS_FAILURE,
                payload: {
                    error: response,
                    loading: false
                }
            });

        };
    },
};

export function promisedDocumentsReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case promisedDocumentsConstants.GET_MISSING_DOCUMENTS:
            return {
                ...state,
                loading: action.payload.loading
            };
        case promisedDocumentsConstants.GET_MISSING_DOCUMENTS_SUCCESS:
            const typeArr = [];
            action.payload.missing.forEach((i)=>{
                i.types.forEach(t=>{
                    typeArr.push(t.id)
                })
            })

            return {
                ...state,
                types: new Set(typeArr),
                missing: action.payload.missing,
                loading: action.payload.loading
            };
        case promisedDocumentsConstants.GET_MISSING_DOCUMENTS_FAILURE:
            return {
                ...state,
                missing: [],
                error: action.payload.error,
                loading: action.payload.loading
            };
        default:
            return state
    }
}
