import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/client-references/',
        'ClientReference',
        'ClientReferences',
        initialState);

export const clientReferenceConstants = constants;
export const clientReferenceActions = actions;
export const clientReferenceReducer = reducer;
