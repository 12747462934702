import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/document-rejection-reasons/',
        'DocumentRejectionReason',
        'DocumentRejectionReasons',
        initialState);

export const documentRejectionReasonConstants = constants;
export const documentRejectionReasonActions = actions;
export const documentRejectionReasonReducer = reducer;
