import {push} from 'connected-react-router';
import axios from './Axios';
import i18n from '../i18n';
import {authActions} from "./auth";

const initialState = {sendingTerms: false};

export const smeConstants = {
    TERMS_REQUEST: 'TERMS_REQUEST',
    TERMS_SUCCESS: 'TERMS_SUCCESS',
    TERMS_FAILURE: 'TERMS_FAILURE',

    DETAILS_REQUEST: 'DETAILS_REQUEST',
    DETAILS_SUCCESS: 'DETAILS_SUCCESS',
    DETAILS_FAILURE: 'DETAILS_FAILURE',

};

export const smeActions = {
    /* terms */
    acceptTerms: ({accepted_terms, accepted_policy, successUrl, failedUrl}) => {
        return (dispatch) => {

            axios.post('/auth/accept-terms-policy/', {accepted_terms, accepted_policy})
                .then((response) => dispatch(smeActions.acceptTermsSuccess(response, successUrl)))
                .catch((response) => dispatch(smeActions.acceptTermsFailed(response, failedUrl)));

            return {
                type: smeConstants.TERMS_REQUEST,
                successUrl: successUrl,
                failedUrl: failedUrl
            };
        };
    },

    acceptTermsSuccess: (response, returnUrl) => {
        return (dispatch) => {

            // update internal user object
            dispatch(authActions.acceptTerms(response))

            if (returnUrl)
                dispatch(push(returnUrl));
            else
                dispatch(push('/sme/dashboard'));

            return {
                type: smeConstants.TERMS_SUCCESS,
                payload: {
                    response
                }
            };

        };
    },

    acceptTermsFailed: (response, returnUrl) => {
        return dispatch => {

            if (returnUrl)
                dispatch(push(returnUrl));

            dispatch({
                type: smeConstants.TERMS_FAILURE,
                payload: {error: response || i18n.t('Request Failed')}
            });

        };
    }

};

export function smeReducer(state = initialState, action) {
    // console.log('smeReducer', action, state);
    switch (action.type) {
        /* login */
        case smeConstants.TERMS_REQUEST:
            return {
                ...state,
                sendingTerms: true,
            };
        case smeConstants.TERMS_SUCCESS:
            return {
                ...state,
                sendingTerms: false,
                response: action.payload.response
            };
        case smeConstants.TERMS_FAILURE:
            return {
                ...state,
                sendingTerms: false,
                error: action.payload.error
            };

        /* details */
        case smeConstants.DETAILS_REQUEST:
            return {
                ...state,
                sendingDetails: true,
            };
        case smeConstants.DETAILS_SUCCESS:
            return {
                ...state,
                sendingDetails: false,
                response: action.payload.response
            };
        case smeConstants.DETAILS_FAILURE:
            return {
                ...state,
                sendingDetails: false,
                error: action.payload.error
            };
        default:
            return state
    }
}
