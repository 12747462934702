import axios from './Axios';

const initialState = { towns: []};

const townConstants = {
    GET_TOWNS: 'GET_TOWNS',
    GET_TOWNS_SUCCESS: 'GET_TOWNS_SUCCESS',
    GET_TOWNS_FAILURE: 'GET_TOWNS_FAILURE',
};

export const townActions = {
    /* get */
    getTowns: (params) => {
        return (dispatch) => {

            axios.get('/main/towns/', {params})
                .then((response) => dispatch(townActions.getTownsSuccess(response)))
                .catch((response) => dispatch(townActions.getTownsFailure(response)));

            return {
                type: townConstants.GET_TOWNS,
            };
        };
    },

    getTownsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: townConstants.GET_TOWNS_SUCCESS,
                payload: {
                    towns: response.items
                }
            });
        };
    },

    getTownsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: townConstants.GET_TOWNS_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function townReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case townConstants.GET_TOWNS:
            return {
                ...state,
                towns: []
            };
        case townConstants.GET_TOWNS_SUCCESS:
            return {
                ...state,
                towns: action.payload.towns
            };
        case townConstants.GET_TOWNS_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
