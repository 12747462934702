import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/company-ranking-document-status/',
        'DocumentStatus',
        'DocumentStatuses',
        initialState);

export const documentStatusConstants = constants;
export const documentStatusActions = actions;
export const documentStatusReducer = reducer;
