import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/platform-settings/',
        'PlatformSetting',
        'PlatformSettings',
        initialState);

export const platformSettingsConstants = constants;
export const platformSettingsActions = actions;
export const platformSettingsReducer = reducer;
