import axios from './Axios';

const initialState = { items: []};

const beeStatusConstants = {
    GET_BEESTATUSES: 'GET_BEESTATUSES',
    GET_BEESTATUSES_SUCCESS: 'GET_BEESTATUSES_SUCCESS',
    GET_BEESTATUSES_FAILURE: 'GET_BEESTATUSES_FAILURE',
};

export const beeStatusActions = {
    /* get */
    getBeeStatuses: () => {
        return (dispatch) => {

            axios.get('/main/bee-statuses/')
                .then((response) => dispatch(beeStatusActions.getBeeStatusesSuccess(response)))
                .catch((response) => dispatch(beeStatusActions.getBeeStatusesFailure(response)));

            return {
                type: beeStatusConstants.GET_BEESTATUSES,
                payload: {
                    loading: true
                }
            };
        };
    },

    getBeeStatusesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: beeStatusConstants.GET_BEESTATUSES_SUCCESS,
                payload: {
                    items: response.items,
                    loading: false
                }
            });
        };
    },

    getBeeStatusesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: beeStatusConstants.GET_BEESTATUSES_FAILURE,
                payload: {
                    error: response,
                    loading: false
                }
            });

        };
    },
};

export function beeStatusReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case beeStatusConstants.GET_BEESTATUSES:
            return {
                ...state,
                loading: action.payload.loading
            };
        case beeStatusConstants.GET_BEESTATUSES_SUCCESS:
            return {
                ...state,
                items: action.payload.items,
                loading: action.payload.loading
            };
        case beeStatusConstants.GET_BEESTATUSES_FAILURE:
            return {
                ...state,
                items: [],
                error: action.payload.error,
                loading: action.payload.loading
            };
        default:
            return state
    }
}
