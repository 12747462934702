import axios from './Axios';

const initialState = { addressTypes: []};

const addressTypeConstants = {
    GET_ADDRESS_TYPES: 'GET_ADDRESS_TYPES',
    GET_ADDRESS_TYPES_SUCCESS: 'GET_ADDRESS_TYPES_SUCCESS',
    GET_ADDRESS_TYPES_FAILURE: 'GET_ADDRESS_TYPES_FAILURE',
};

export const addressTypeActions = {
    /* get */
    getAddressTypes: () => {
        return (dispatch) => {

            axios.get('/main/address-types/')
                .then((response) => dispatch(addressTypeActions.getAddressTypesSuccess(response)))
                .catch((response) => dispatch(addressTypeActions.getAddressTypesFailure(response)));

            return {
                type: addressTypeConstants.GET_ADDRESS_TYPES,
            };
        };
    },

    getAddressTypesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: addressTypeConstants.GET_ADDRESS_TYPES_SUCCESS,
                payload: {
                    addressTypes: response.items
                }
            });
        };
    },

    getAddressTypesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: addressTypeConstants.GET_ADDRESS_TYPES_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function addressTypeReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case addressTypeConstants.GET_ADDRESS_TYPES:
            return {
                ...state
            };
        case addressTypeConstants.GET_ADDRESS_TYPES_SUCCESS:
            return {
                ...state,
                addressTypes: action.payload.addressTypes
            };
        case addressTypeConstants.GET_ADDRESS_TYPES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
