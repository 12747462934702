import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/notification/messages/',
        'NotificationMessage',
        'NotificationMessages',
        initialState);

export const notificationMessageConstants = constants;
export const notificationMessageActions = actions;
export const notificationMessageReducer = reducer;