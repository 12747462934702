import axios from './Axios';

const initialState = {missing: []};

const missingGradingDocumentsConstants = {
    GET_MISSING_GRADING_DOCUMENTS: 'GET_MISSING_GRADING_DOCUMENTS',
    GET_MISSING_GRADING_DOCUMENTS_SUCCESS: 'GET_MISSING_GRADING_DOCUMENTS_SUCCESS',
    GET_MISSING_GRADING_DOCUMENTS_FAILURE: 'GET_MISSING_GRADING_DOCUMENTS_FAILURE',

    POST_MISSING_GRADING_DOCUMENTS: 'POST_MISSING_GRADING_DOCUMENTS',
    POST_MISSING_GRADING_DOCUMENTS_SUCCESS: 'POST_MISSING_GRADING_DOCUMENTS_SUCCESS',
    POST_MISSING_GRADING_DOCUMENTS_FAILURE: 'POST_MISSING_GRADING_DOCUMENTS_FAILURE',
};

export const missingGradingDocumentsActions = {
    // get missing grading documents
    getMissingGradingDocuments: (smid) => {
        return (dispatch) => {
            return axios.get(`/main/missing-grading-documents/?sme_company_id=${smid}`)
                .then((response) => {
                    dispatch(missingGradingDocumentsActions.getMissingGradingDocumentsSuccess(response));
                    return response; // Return response to allow chaining
                })
                .catch((error) => {
                    dispatch(missingGradingDocumentsActions.getMissingGradingDocumentsFailure(error));
                    throw error; // Re-throw error to allow chaining
                });
        };
    },
    
    getMissingGradingDocumentsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: missingGradingDocumentsConstants.GET_MISSING_GRADING_DOCUMENTS_SUCCESS,
                payload: {
                    missing: response.data,
                    loading: false
                }
            });
        };
    },

    getMissingGradingDocumentsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: missingGradingDocumentsConstants.GET_MISSING_GRADING_DOCUMENTS_FAILURE,
                payload: {
                    error: response,
                    loading: false
                }
            });

        };
    },

    // post missing grading documents
    postMissingGradingDocuments: (data) => {
        return (dispatch) => {
            // Return the promise directly to the caller
            return axios.post(`/main/missing-grading-documents/`, data)
                .then((response) => {
                    dispatch(missingGradingDocumentsActions.postMissingGradingDocumentsSuccess(response));
                    return response; // Return response to allow chaining
                })
                .catch((error) => {
                    dispatch(missingGradingDocumentsActions.postMissingGradingDocumentsFailure(error));
                    throw error; // Re-throw error to allow chaining
                });
        };
    },
    

    postMissingGradingDocumentsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: missingGradingDocumentsConstants.POST_MISSING_GRADING_DOCUMENTS_SUCCESS,
                payload: {
                    missing: response.data,
                    loading: false
                }
            });
        };
    },

    postMissingGradingDocumentsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: missingGradingDocumentsConstants.POST_MISSING_GRADING_DOCUMENTS_FAILURE,
                payload: {
                    error: response,
                    loading: false
                }
            });

        };
    }
};

export function missingGradingDocumentsReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case missingGradingDocumentsConstants.GET_MISSING_GRADING_DOCUMENTS:
            return {
                ...state,
                loading: action.payload.loading
            };
        case missingGradingDocumentsConstants.GET_MISSING_GRADING_DOCUMENTS_SUCCESS:
            return {
                ...state,
                missing: action.payload.missing,
                loading: action.payload.loading
            };
        case missingGradingDocumentsConstants.GET_MISSING_GRADING_DOCUMENTS_FAILURE:
            return {
                ...state,
                missing: [],
                error: action.payload.error,
                loading: action.payload.loading
            };
        default:
            return state
    }
}