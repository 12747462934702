import axios from './Axios'

const initialState = { statusData: null, loading: false }

const questionnaireResultConstants = {
	GET_STATUS: 'GET_STATUS',
	GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
	GET_STATUS_FAILURE: 'GET_STATUS_FAILURE',
	UNLOCK_REQUEST: 'UNLOCK_REQUEST',
	UNLOCK_SUCCESS: 'UNLOCK_SUCCESS',
	UNLOCK_FAILED: 'UNLOCK_FAILED',
	ON_RESET_DATA: 'ON_RESET_DATA',
}

export const questionnaireResultActions = {
	// API call simulation
	getData: smeCompanyId => {
		return dispatch => {
			dispatch({
				type: questionnaireResultConstants.GET_STATUS,
			})
			axios
				.get(`/main/over_all_result/?sme_company_id=${smeCompanyId}`)
				.then(response => dispatch(questionnaireResultActions.getDataSuccess(response.data)))
				.catch(response => dispatch(questionnaireResultActions.getDataFailure(response)))
		}
	},
	getDataSuccess: response => {
		return dispatch => {
			dispatch({
				type: questionnaireResultConstants.GET_STATUS_SUCCESS,
				payload: response,
			})
		}
	},
	getDataFailure: () => {
		return dispatch => {
			dispatch({
				type: questionnaireResultConstants.GET_STATUS_FAILURE,
			})
		}
	},
	onRequestUnlock: sme_company_id => {
		return dispatch => {
			dispatch({
				type: questionnaireResultConstants.UNLOCK_REQUEST,
			})
			axios
				.put(`/main/request_to_unlock`, { sme_company_id })
				.then(response => {
					dispatch(questionnaireResultActions.getData(sme_company_id))
					dispatch(questionnaireResultActions.requestUnlockSuccess(response.data))
				})
				.catch(response => dispatch(questionnaireResultActions.requestUnlockFailure(response)))
		}
	},
	requestUnlockSuccess: response => {
		return dispatch => {
			dispatch({
				type: questionnaireResultConstants.UNLOCK_SUCCESS,
				payload: response,
			})
		}
	},
	requestUnlockFailure: () => {
		return dispatch => {
			dispatch({
				type: questionnaireResultConstants.UNLOCK_FAILED,
			})
		}
	},
	resetData: () => {
		return dispatch => {
			dispatch({
				type: questionnaireResultConstants.ON_RESET_DATA,
			})
		}
	},
}

export function questionnaireResultReducer(state = initialState, action) {
	switch (action.type) {
		case questionnaireResultConstants.GET_STATUS:
			return {
				...state,
				loading: true,
			}
		case questionnaireResultConstants.GET_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				statusData: action.payload,
			}
		case questionnaireResultConstants.GET_STATUS_FAILURE:
			return {
				...state,
				loading: false,
			}
		case questionnaireResultConstants.UNLOCK_REQUEST:
			return {
				...state,
				loading: true,
			}

		case questionnaireResultConstants.UNLOCK_SUCCESS:
			return {
				...state,
				loading: false,
			}
		case questionnaireResultConstants.UNLOCK_FAILED:
			return {
				...state,
				loading: false,
			}
		case questionnaireResultConstants.ON_RESET_DATA:
			return {
				...state,
				statusData: null,
			}
		default:
			return state
	}
}
