import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/company-documents/',
        'CompanyDocument',
        'CompanyDocuments',
        initialState);

export const companyDocumentConstants = constants;
export const companyDocumentActions = actions;
export const companyDocumentReducer = reducer;
