import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/loan_application_liabilities/',
        'AddLiabilities',
        'AddLiabilities',
        initialState);

export const addLiabilitiesConstants = constants;
export const addLiabilitiesActions = actions;
export const addLiabilitiesReducer = reducer;