const initialState = {
    items: [
        {id: 'system', name: 'system'},
        {id: 'super-admin', name: 'Super Admin'},
        {id: 'client-admin', name: 'Client Admin'},
        {id: 'contact-centre', name: 'Contact Centre'},
        {id: 'sme', name: 'SME'},
        {id: 'scm-admin', name: 'SCM Admin'},
        {id: 'scm-submitter', name: 'SCM Submitter'},
        {id: 'scm-approver', name: 'SCM Approver'},
        {id: 'esd', name: 'ESD'},
        {id: 'esd-pm', name: 'ESD PM'},
        {id: 'esd-client', name: 'ESD Client'},
        {id: 'esd-facilitator', name: 'ESD Facilitator'},
        {id: 'funding-specialist', name: 'Funding Specialist'},
        {id: 'commercial-specialist',  name: 'Commercial Specialist'},
        {id: 'accreditation-specialist',  name: 'Accreditation Specialist'}
    ]
};

export const PermissionReducer = (state = initialState) => {
    return state
};