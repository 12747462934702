import axios from './Axios'
const initialState = {
	questions: [],
	isSubmitted: false,
	isAttended: false,
	total_score: '',
	submitMode: false,
	answers: [],
	showResult: false,
	result: {},
	loading: false,
	questionnaireStatus: { saveCheckStatus: true, TermsOrConditionStatus: false, score: null,status : "" },
	buttonLoading: false,
}
const QuestionnaireConstants = {
	GET_QUESTIONS: 'GET_QUESTIONS',
	GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
	GET_QUESTIONS_FAILURE: 'GET_QUESTIONS_FAILURE',
	ON_ANSWER: 'ON_ANSWER',
	ON_SUBMIT: 'ON_SUBMIT',
	ON_SAVE_DRAFT: 'ON_SAVE_DRAFT',
	SAVE_DRAFT_SUCCESS: 'SAVE_DRAFT_SUCCESS',
	SAVE_DRAFT_FAILED: 'SAVE_DRAFT_FAILED',
	ON_CANCEL: 'ON_CANCEL',
	ON_CLOSE_DRAFT_RESULT: 'ON_CLOSE_DRAFT_RESULT',
	ON_CLOSE_SUBMIT_RESULT: 'ON_CLOSE_SUBMIT_RESULT',
	ON_SUBMIT_ANSWERS_REQUEST: 'ON_SUBMIT_ANSWERS_REQUEST',
	ON_SUBMIT_ANSWERS_SUCCESS: 'ON_SUBMIT_ANSWERS_SUCCESS',
	ON_SUBMIT_ANSWERS_FAILED: 'ON_SUBMIT_ANSWERS_FAILED',
	GET_QUESTIONNAIRE_STATUS: 'GET_QUESTIONNAIRE_STATUS',
	GET_QUESTIONNAIRE_STATUS_SUCCESS: 'GET_QUESTIONNAIRE_STATUS_SUCCESS',
	GET_QUESTIONNAIRE_STATUS_FAILURE: 'GET_QUESTIONNAIRE_STATUS_FAILURE',
	ON_ACCEPT_TERMS: 'ON_ACCEPT_TERMS',
	ON_ACCEPT_TERMS_SUCCESS: 'ON_ACCEPT_TERMS_SUCCESS',
	ON_ACCEPT_TERMS_FAILURE: 'ON_ACCEPT_TERMS_FAILURE',
	ON_RESET_SUBMIT_MODE: 'ON_RESET_SUBMIT_MODE',
	UNLOCK_QUESTIONNAIRE: 'UNLOCK_QUESTIONNAIRE',
	UNLOCK_QUESTIONNAIRE_SUCCESS: 'UNLOCK_QUESTIONNAIRE_SUCCESS',
	UNLOCK_QUESTIONNAIRE_FAILED: 'UNLOCK_QUESTIONNAIRE_FAILED',
	REJECT_QUESTIONNAIRE: 'REJECT_QUESTIONNAIRE',
	REJECT_QUESTIONNAIRE_SUCCESS: 'REJECT_QUESTIONNAIRE_SUCCESS',
	REJECT_QUESTIONNAIRE_FAILED: 'REJECT_QUESTIONNAIRE_FAILED',
	COMPLETE_QUESTIONNAIRE_PROMPT: 'COMPLETE_QUESTIONNAIRE_PROMPT',
	COMPLETE_QUESTIONNAIRE_PROMPT_SUCCESS: 'COMPLETE_QUESTIONNAIRE_PROMPT_SUCCESS',
	COMPLETE_QUESTIONNAIRE_PROMPT_FAILED: 'COMPLETE_QUESTIONNAIRE_PROMPT_FAILED',
}

export const sheQuestionActions = {
	onAcceptTermsAndConditions: sme_company_id => {
		return dispatch => {
			axios
				.post('/main/save_terms_and_condition', { sme_company_id })
				.then(response => {
					dispatch({
						type: QuestionnaireConstants.ON_ACCEPT_TERMS_SUCCESS,
						payload: response.data,
					})
				})
				.catch(error => {
					dispatch({
						type: QuestionnaireConstants.ON_ACCEPT_TERMS_FAILURE,
						error: error,
					})
				})
		}
	},
	getQuestionnaireStatus: company_id => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.GET_QUESTIONNAIRE_STATUS,
			})
			axios
				.get(`/main/accepted_or_not/?sme_company_id=${company_id}`)
				.then(response => {
					dispatch({ type: QuestionnaireConstants.GET_QUESTIONNAIRE_STATUS_SUCCESS, payload: response.data })
					localStorage.setItem('questionnaireStatus', JSON.stringify(response.data))
				})
				.catch(er => dispatch({ type: QuestionnaireConstants.GET_QUESTIONNAIRE_STATUS_FAILURE, error: er }))
		}
	},
	onAnswerQuestion: payload => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.ON_ANSWER,
				payload: payload,
			})
		}
	},
	onSubmitQuestionnaire: payload => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.ON_SUBMIT,
			})
			if (payload) {
				dispatch({
					type: QuestionnaireConstants.ON_SUBMIT_ANSWERS_REQUEST,
				})

				axios
					.post('/main/save-questionnaire', payload)
					.then(response => {
						dispatch({
							type: QuestionnaireConstants.ON_SUBMIT_ANSWERS_SUCCESS,
							payload: response,
						})
					})
					.catch(error => {
						dispatch({
							type: QuestionnaireConstants.ON_SUBMIT_ANSWERS_FAILED,
						})
					})
			}
		}
	},
	onCancelQuestionnaire: () => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.ON_CANCEL,
			})
		}
	},
	onSaveDraftQuestionnaire: payload => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.ON_SAVE_DRAFT,
			})

			axios
				.post('/main/save-questionnaire', payload)
				.then(response => {
					dispatch({
						type: QuestionnaireConstants.SAVE_DRAFT_SUCCESS,
						payload: response,
					})
				})
				.catch(error => {
					dispatch({
						type: QuestionnaireConstants.SAVE_DRAFT_FAILED,
						payload: error,
					})
				})
		}
	},
	onCloseResult: isSubmitted => {
		return dispatch => {
			if (isSubmitted) {
				dispatch({ type: QuestionnaireConstants.ON_CLOSE_SUBMIT_RESULT })
			} else {
				dispatch({ type: QuestionnaireConstants.ON_CLOSE_DRAFT_RESULT })
			}
		}
	},
	getQuestions: company_id => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.GET_QUESTIONS,
			})

			axios
				.get(`/main/fetch-questionnaire/?sme_company_id=${company_id}`)
				.then(response => dispatch(sheQuestionActions.getQuestionsSuccess(response)))
				.catch(response => dispatch(sheQuestionActions.getQuestionsFailure(response)))
		}
	},

	getQuestionsSuccess: data => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.GET_QUESTIONS_SUCCESS,
				payload: data,
			})
		}
	},

	getQuestionsFailure: response => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.GET_QUESTIONS_FAILURE,
				payload: { error: response },
			})
		}
	},
	onResetSubmitMode: () => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.ON_RESET_SUBMIT_MODE,
			})
		}
	},
	onUnlockQuestionnaire: unlockParams => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.UNLOCK_QUESTIONNAIRE,
			})

			axios
				.put(`/main/admin_action_for_request`, unlockParams)
				.then(response => dispatch({ type: QuestionnaireConstants.UNLOCK_QUESTIONNAIRE_SUCCESS, payload: response }))
				.catch(response => dispatch({ type: QuestionnaireConstants.UNLOCK_QUESTIONNAIRE_FAILED, error: response }))
		}
	},
	onRejectQuestionnaire: rejectParams => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.REJECT_QUESTIONNAIRE,
			})

			axios
				.put(`/main/admin_action_for_request`, rejectParams)
				.then(response => dispatch({ type: QuestionnaireConstants.REJECT_QUESTIONNAIRE_SUCCESS, payload: response }))
				.catch(response => dispatch({ type: QuestionnaireConstants.REJECT_QUESTIONNAIRE_FAILED, error: response }))
		}
	},
	onPromptToComplete: payload => {
		return dispatch => {
			dispatch({
				type: QuestionnaireConstants.COMPLETE_QUESTIONNAIRE_PROMPT,
			})

			return axios
				.post('/main/complete_she_questionnaire_alert', payload)
				.then(response => {
					dispatch({
						type: QuestionnaireConstants.COMPLETE_QUESTIONNAIRE_PROMPT_SUCCESS,
						payload: response,
					})
					return response
				})
				.catch(error => {
					dispatch({
						type: QuestionnaireConstants.COMPLETE_QUESTIONNAIRE_PROMPT_FAILED,
						payload: error,
					})
				})
		}
	},
	fetchInterestStatus: payload => {
		const { opportunity_id, sme_company_id } = payload
		return () => {
			return axios
				.get(`/main/get_interested_flag/?sme_company_id=${sme_company_id}&opportunity_id=${opportunity_id}`)
				.then(response => {
					return response
				})
				.catch(response => response)
		}
	},
}

export function sheQuestionnaireReducer(state = initialState, action) {
	switch (action.type) {
		case QuestionnaireConstants.GET_QUESTIONS:
			return {
				...state,
				loading: true,
			}
		/* get */
		case QuestionnaireConstants.GET_QUESTIONS_SUCCESS:
			return {
				...state,
				questions: action.payload.data,
				answers: action.payload.data,
				isAttended : action.payload.is_attended,
				isSubmitted: action.payload.is_submitted,
				total_score: action.payload.total_score,
				comment: action.payload.comment,
				compliance_status: action.payload.compliance_status,
				compliance_date: action.payload.compliance_date,
				loading: false,
			}
		case QuestionnaireConstants.GET_QUESTIONS_FAILURE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}
		case QuestionnaireConstants.ON_ANSWER:
			return {
				...state,
				answers: action.payload,
			}
		case QuestionnaireConstants.ON_SUBMIT:
			return {
				...state,
				submitMode: true,
			}
		case QuestionnaireConstants.ON_SUBMIT_ANSWERS_REQUEST:
			return {
				...state,
				loading: true,
			}
		case QuestionnaireConstants.ON_SAVE_DRAFT:
			return {
				...state,
				loading: true,
			}
		case QuestionnaireConstants.SAVE_DRAFT_SUCCESS:
			return {
				...state,
				submitMode: false,
				showResult: true,
				result: action.payload,
				loading: false,
			}
		case QuestionnaireConstants.SAVE_DRAFT_FAILED:
			return {
				...state,
				loading: false,
			}
		case QuestionnaireConstants.ON_CANCEL:
			return {
				...state,
				submitMode: false,
				answers: [],
				isSubmitted: false,
				total_score: '',
			}
		case QuestionnaireConstants.ON_CLOSE_SUBMIT_RESULT:
			return {
				...state,
				showResult: false,
				result: {},
				answers: [],
			}
		case QuestionnaireConstants.ON_CLOSE_DRAFT_RESULT:
			return {
				...state,
				showResult: false,
				result: {},
			}
		case QuestionnaireConstants.ON_SUBMIT_ANSWERS_SUCCESS:
			return {
				...state,
				showResult: true,
				result: action.payload,
				isSubmitted: false,
				loading: false,
			}
		case QuestionnaireConstants.ON_SUBMIT_ANSWERS_FAILED:
			return {
				...state,
				showResult: false,
				isSubmitted: false,
				loading: false,
			}
		case QuestionnaireConstants.GET_QUESTIONNAIRE_STATUS:
			return {
				...state,
				buttonLoading: true,
			}
		case QuestionnaireConstants.GET_QUESTIONNAIRE_STATUS_SUCCESS:
			return {
				...state,
				questionnaireStatus: action.payload,
				buttonLoading: false,
			}
		case QuestionnaireConstants.GET_QUESTIONNAIRE_STATUS_FAILURE:
			return {
				...state,
				buttonLoading: false,
			}
		case QuestionnaireConstants.ON_ACCEPT_TERMS_SUCCESS:
			return {
				...state,
				questionnaireStatus: { ...state.questionnaireStatus, TermsOrConditionStatus: true },
			}
		case QuestionnaireConstants.ON_RESET_SUBMIT_MODE:
			return {
				...state,
				submitMode: false,
			}
		case QuestionnaireConstants.COMPLETE_QUESTIONNAIRE_PROMPT:
			return {
				...state,
				loading: true,
			}
		case QuestionnaireConstants.COMPLETE_QUESTIONNAIRE_PROMPT_SUCCESS:
			return {
				...state,
				loading: false,
			}
		case QuestionnaireConstants.COMPLETE_QUESTIONNAIRE_PROMPT_FAILED:
			return {
				...state,
				loading: false,
			}
		default:
			return state
	}
}
