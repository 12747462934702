
import axios from './Axios';

const initialState = { genders: []};

const genderConstants = {
    GET_GENDERS: 'GET_GENDERS',
    GET_GENDERS_SUCCESS: 'GET_GENDERS_SUCCESS',
    GET_GENDERS_FAILURE: 'GET_GENDERS_FAILURE',
};

export const genderActions = {
    /* get */
    getGenders: (params) => {
        return (dispatch) => {

            axios.get('/main/genders/',{params})
                .then((response) => dispatch(genderActions.getGendersSuccess(response)))
                .catch((response) => dispatch(genderActions.getGendersFailure(response)));

            return {
                type: genderConstants.GET_GENDERS,
            };
        };
    },

    getGendersSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: genderConstants.GET_GENDERS_SUCCESS,
                payload: {
                    genders: response.items
                }
            });
        };
    },

    getGendersFailure: (response) => {
        return dispatch => {

            dispatch({
                type: genderConstants.GET_GENDERS_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function genderReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case genderConstants.GET_GENDERS:
            return {
                ...state
            };
        case genderConstants.GET_GENDERS_SUCCESS:
            return {
                ...state,
                genders: action.payload.genders
            };
        case genderConstants.GET_GENDERS_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
