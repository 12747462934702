import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/company-rankings/',
        'CompanyRanking',
        'CompanyRankings',
        initialState);

export const companyRankingConstants = constants;
export const companyRankingActions = actions;
export const companyRankingReducer = reducer;
