import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/race/',
        'Race',
        'Races',
        initialState);

export const raceConstants = constants;
export const raceActions = actions;
export const raceReducer = reducer;

