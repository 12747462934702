import {createCrud} from "./CRUD";

const initialState = { items: [
        {
            "id": 1,
            "title": "Fortnightly"
        },
        {
            "id": 2,
            "title": "Monthly"
        }
    ], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/growth-plan-periods/',
        'GrowthPlanPeriod',
        'GrowthPlanPeriods',
        initialState,
        []);

export const growthPlanPeriodConstants = constants;
export const growthPlanPeriodActions = actions;
export const growthPlanPeriodReducer = reducer;
