import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/subscription/',
        'Subscription',
        'Subscriptions',
        initialState);

export const subscriptionConstants = constants;
export const subscriptionActions = actions;
export const subscriptionReducer = reducer;
