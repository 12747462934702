import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { authActions } from '../../store/auth'
import { PrivateRouteWithLayout, RouteWithLayout } from './Router'
import { platformFeaturesActions } from '../../store/PlatformFeatures'
import usePlatformFeatures from '../../hooks/use-platform-features'
import * as Sentry from "@sentry/react";
// CONSTANTS
import {ENVIRONMENT_CLASS,ZEVOLI_CLASS, OTP, MAINTENANCE_MODE } from '../../constants/constants'
import debounce from 'lodash/debounce';
import LoginReusePrevention from "../LoginReusePrevention";
import {loginReusePreventionActions} from "../../store/LoginReusePrevention";
const StyleGuide = lazy(() => import('../pages/styleguide/index').then(m => ({ default: m.default })))

const LayoutPage = lazy(() => import('../layout/Layout').then(({ Layout }) => ({ default: Layout })))
const Home = lazy(() => import('../pages/Home').then(m => ({ default: m.default })))
const Login = lazy(() => import('../pages/Login').then(m => ({ default: m.default })))
const OTPVerification = lazy(() => import('../pages/OTPVerification').then(m => ({ default: m.default })))
const OTPEnable = lazy(() => import('../pages/OTPEnable').then(m => ({ default: m.default })))
const HOTPEnable = lazy(() => import('../pages/HOTPEnable').then(m => ({ default: m.default })))
const TOTPEnable = lazy(() => import('../pages/TOTPEnable').then(m => ({ default: m.default })))
const AccessDenied = lazy(() => import('../pages/AccessDenied').then(m => ({ default: m.default })))
const NotFound = lazy(() => import('../pages/NotFound').then(m => ({ default: m.default })))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword').then(m => ({ default: m.default })))
const InternalError = lazy(() => import('../pages/InternalError').then(m => ({ default: m.default })))
const Logout = lazy(() => import('../pages/Logout').then(m => ({ default: m.default })))
const SignUp = lazy(() => import('../pages/Signup').then(m => ({ default: m.default })))
const SelfRegister = lazy(() => import('../pages/SelfRegister').then(m => ({ default: m.default })))
const ResetPassword = lazy(() => import('../pages/ResetPassword').then(m => ({ default: m.default })))
const SendInvite = lazy(() => import('../pages/SendInvite').then(m => ({ default: m.default })))
const Support = lazy(() => import('../pages/support/support').then(m => ({ default: m.default })))
const SmeLayout = lazy(() => import('./sme/SmeLayout').then(m => ({ default: m.default })))
const Faq = lazy(() => import('../pages/faq/faq').then(m => ({ default: m.default })))

const EmptyLayout = lazy(() => import('./EmptyLayout').then(m => ({ default: m.default })))

const SMERouter = lazy(() => import('../layout/sme/SmeDashboardLayout').then(({ SmeRouter }) => ({ default: SmeRouter })))
const ADMINRouter = lazy(() => import('../layout/admin/AdminDashboardLayout').then(({ AdminRouter }) => ({ default: AdminRouter })))
const SCMRouter = lazy(() => import('../layout/scm/ScmDashboardLayout').then(({ ScmRouter }) => ({ default: ScmRouter })))
const ESDRouter = lazy(() => import('../layout/esd/EsdDashboardLayout').then(({ EsdRouter }) => ({ default: EsdRouter })))
const FSRouter = lazy(() => import('../layout/fs/FsDashboardLayout').then(({ FsRouter }) => ({ default: FsRouter })))
const CSRouter = lazy(() => import('./cs/CsDashboardLayout').then(({ CsRouter }) => ({ default: CsRouter })))
const ASRouter = lazy(() => import('./as/AsDashboardLayout').then(({ AsRouter }) => ({ default: AsRouter })))

const MetaBase = lazy(() => import('../MetaBase').then(m => ({ default: m.default })))
const ChooseCompany = lazy(() => import('../pages/ChooseCompany').then(m => ({ default: m.default })))
const TokenExpired = lazy(() => import('../TokenExpired').then(m => ({ default: m.default })))
const MaintenancePage = lazy(() => import('../pages/MaintenancePage').then(m => ({ default: m.default })));

const _routes = [
	{ path: '/', exact: true, component: Home, layout: EmptyLayout, guard: false },
	{ path: '/forgot-password', exact: true, component: ForgotPassword, layout: LayoutPage, guard: false },
	{ path: '/reset-password', exact: true, component: ResetPassword, layout: LayoutPage, guard: false },
	{ path: '/login', exact: true, component: Login, layout: LayoutPage, guard: false },
	{ path: '/choose-company', exact: true, lazyLoad: true, component: ChooseCompany, layout: LayoutPage, guard: ['sme'] },
	{ path: '/logout', exact: true, component: Logout, layout: LayoutPage, guard: false },
	{ path: '/sign-up/:ref/:id', exact: true, component: SignUp, layout: LayoutPage, guard: false },
	{ path: '/self-register', exact: true, component: SelfRegister, layout: LayoutPage, guard: false },
	{ path: '/l/:ref', exact: true, component: SignUp, layout: LayoutPage, guard: false },
	{ path: '/403', exact: true, component: AccessDenied, layout: EmptyLayout, guard: false },
	{ path: '/404', exact: true, component: NotFound, layout: EmptyLayout, guard: false },
	{ path: '/500', exact: true, component: InternalError, layout: EmptyLayout, guard: false },
	{ path: '/faq', exact: true, component: Faq, layout: SmeLayout, guard: false },
	{ path: '/support', exact: true, component: Support, layout: SmeLayout, guard: false },
	{
		path: '/admin',
		component: ADMINRouter,
		layout: EmptyLayout,
		lazyLoad: true,
		guard: ['super-admin', 'client-admin', 'esd', 'esd-pm', 'esd-client', 'esd-facilitator', 'funding-specialist', 'contact-centre'],
	},
	{
		path: '/scm',
		component: SCMRouter,
		layout: EmptyLayout,
		lazyLoad: true,
		guard: ['scm-approver', 'scm-submitter', 'scm-admin'],
	},
	{
		path: '/esd',
		component: ESDRouter,
		layout: EmptyLayout,
		lazyLoad: true,
		guard: ['esd'],
	},
	{
		path: '/funding-specialist',
		component: FSRouter,
		layout: EmptyLayout,
		lazyLoad: true,
		guard: ['funding-specialist'],
	},
	{
		path: '/commercial-specialist',
		component: CSRouter,
		layout: EmptyLayout,
		lazyLoad: true,
		guard: ['commercial-specialist'],
	},
	{
		path: '/accreditation-specialist',
		component: ASRouter,
		layout: EmptyLayout,
		lazyLoad: true,
		guard: ['accreditation-specialist'],
	},
	{ path: '/sme', component: SMERouter, layout: EmptyLayout, lazyLoad: true, guard: ['sme'] },

	{ path: '/invite', exact: true, component: SendInvite, layout: LayoutPage, guard: ['super-admin'] },
	{ path: '/otp-verification', exact: true, component: OTPVerification, layout: LayoutPage, guard: false },
	{ path: '/otp-manage', exact: true, component: OTPEnable, layout: LayoutPage, guard: false },
	{ path: '/hotp-manage', exact: true, component: HOTPEnable, layout: LayoutPage, guard: false },
	{ path: '/totp-manage', exact: true, component: TOTPEnable, layout: LayoutPage, guard: false },

]

const _dev_routes = [
	// delete later
	{ path: '/metabase', exact: true, component: MetaBase, layout: SmeLayout, guard: false },
	{ path: '/style-guide', exact: true, component: StyleGuide, layout: EmptyLayout, guard: false },
]

const _otp_routes_keys = [
    '/otp-verification',
    '/otp-manage',
    '/hotp-manage',
    '/totp-manage'
];

let routes = []
if (process.env.NODE_ENV === 'production') routes = [..._routes]
else routes = [..._routes, ..._dev_routes]

const App = props => {
    const { auth, getPlatformFeatures, checkExistingSession, lrp } = props;
    let renderedRoutes = [];
    const history = useHistory();
    const { getFeature, isFeaturePresent } = usePlatformFeatures();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        // this platform settings is for each platform
        // e.g. zevoli, vanilla
        getPlatformFeatures({ per_page: 1000 }).finally(() => {
            setIsLoading(false);
            const environment_class = getFeature(ENVIRONMENT_CLASS);
            if (environment_class) {
                document.body.classList.add(environment_class);
            } else {
                document.body.classList.add(ZEVOLI_CLASS);
            }
        });
    }, [getPlatformFeatures, getFeature]);


	const autofillFreshDesk = useCallback(() => {
		if (auth.user?.email) {

			Sentry.setUser({email: auth.user.email})
			window.FreshworksWidget('identify', 'ticketForm', {
				name: `${auth.user.first_name} ${auth.user.last_name}`,
				email: auth.user.email,
			})
		}
	}, [auth.user])

	const handleOnIdle = () => {
		// disable this shit on local host
		if (window.location.host.indexOf('local') >= 0) return

		if (auth.user && auth.loggedIn) {
			history.push('/logout')
		}
	}

	const handleOnAction = useCallback(debounce(() => {
		if (auth?.user?.email && lrp?.starting === false && process.env.NODE_ENV !== 'development') {
			checkExistingSession(auth?.user?.email)
		}
	},5000, {trailing: false, leading: true}))

	useIdleTimer({
		timeout: 1000 * 60 * 10,
		onIdle: handleOnIdle,
		onAction: handleOnAction,
		debounce: 500,
	})

	let route404 = null

	for (const f in routes) {
		if (!routes.hasOwnProperty(f)) continue
		const route = routes[f]
		route.key = route.key || f || Date.now()
		route.auth = auth
		if (isFeaturePresent(OTP) !== true  && _otp_routes_keys.includes(route.path)) {
			continue;
		}

		if (route.path === '/404') {
			route404 = route
		} else {
			if (route.guard) {
				renderedRoutes.push(PrivateRouteWithLayout(route))
			} else {
				renderedRoutes.push(RouteWithLayout(route))
			}
		}
	}

	useEffect(() => {
		if (auth?.user?.email && !lrp?.starting) {
			checkExistingSession(auth.user.email, true);
		}
		const location = history?.location
		if (
			auth?.dashboardType === 'sme' &&
			history?.location?.pathname !== '/sme/welcome' &&
			(history?.location?.pathname.startsWith('/sme') || history?.location?.pathname === '/') &&
			auth?.user?.accepted_policy === false &&
			auth?.user?.accepted_terms === false
		) {
			history.push(`/${auth?.dashboardType}/welcome`)
		} else {
			const pathType = location?.pathname?.split('/')[1]

			if (
				(pathType === 'sme' ||
					pathType === 'admin' ||
					pathType === 'scm' ||
					pathType === 'esd' ||
					pathType === 'funding-specialist' ||
					pathType === 'commercial-specialist' ||
					pathType === 'accreditation-specialist') &&
				pathType !== auth?.dashboardType
			) {
				sessionStorage.clear()
				sessionStorage.setItem('prevPath', JSON.stringify({ ...location, dashboardType: pathType }))
				history.push('/logout')
			}
		}
	}, [auth, history])

	// as a last route add 404
	renderedRoutes.push(RouteWithLayout({ ...route404, path: '*' }))

	// Check if maintenance mode is enabled
	if (isLoading) {
		return null; // or a loading spinner
	}

	const maintenanceMode = getFeature(MAINTENANCE_MODE);
	
	if (maintenanceMode === true) {
		return (
			<Suspense fallback={<div>Loading...</div>}>
				<MaintenancePage />
			</Suspense>
		);
	}
	
    return (
        <>
            <Switch>{renderedRoutes}</Switch>
            {auth.user && auth.loggedIn ? autofillFreshDesk() : null}
            <TokenExpired auth={auth} />
            {process.env.NODE_ENV !== 'development' && <LoginReusePrevention />}
        </>
    );
};


const mapStateToProps = state => {
	return {
		auth: state.auth,
		router: state.router,
		lrp: state.loginReusePrevention
	}
}

export default connect(
	state => mapStateToProps(state),
	dispatch =>
		bindActionCreators(
			{
				...authActions,
				...platformFeaturesActions,
				...loginReusePreventionActions,
				dispatch,
			},
			dispatch
		)
)(withRouter(App))
