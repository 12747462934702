import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/users/',
        'User',
        'Users',
        initialState);

export const userConstants = constants;
export const userActions = actions;
export const userReducer = reducer;
