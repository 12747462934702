import {Route, Switch} from "react-router";
import React, {lazy, useEffect, useState} from "react";
import {connect} from "react-redux";
import {Redirect, useRouteMatch} from "react-router-dom";
import hasPermission from "../../utils/HasPermission";
import usePlatformFeatures from "../../hooks/use-platform-features";

const NotFound = lazy(() => import("../pages/NotFound").then((m) => ({default: m.default})));

export const RouteWithLayout = ({auth, layout, component, ...rest}) => {

    if (!rest.path) {
        console.warn('route path is undefined', rest);
        return;
    }

    return (
        <Route key={rest.path} {...rest} render={(props) =>
            React.createElement(connect(state => state, dispatch => {
                return {dispatch}
            })(layout), {}, React.createElement(component, props))
        }/>
    );
};

export const PrivateRouteWithLayout = ({auth, layout, component, ...rest}) => {

    if (!rest.path) {
        console.warn('route path is undefined', rest);
        return;
    }
    let allowed = false;
    let g = rest.guard;
    if (typeof (g) === 'boolean') {
        allowed = true;
    } else if (Array.isArray(g)) {
        for (let r of g) {
            allowed = hasPermission(r)
            if (allowed)
                break
        }
    }

    return (
        <Route key={rest.path} {...rest} render={({staticContext, props}) => (
            allowed === true
                ? React.createElement(connect(state => state, dispatch => {
                    return {dispatch}
                })(layout), {}, React.createElement(component, props))
                : <Redirect to={`/403?f=${rest.path}`}/>
        )}/>
    )
};

export const PrepareRoutes = (props) => {

    const router = useRouteMatch();
    const [is404, setIs404] = useState(false);

    useEffect(() => {
        setIs404(!router.isExact);
    }, [router.isExact]);

    const {auth, routes} = props;
    const [renderedRoutes, setRenderedRoutes] = useState([]);
    const {isFeaturePresent} = usePlatformFeatures();

    useEffect(() => {

        const result = [];
        for (const r in routes) {
            if (!routes.hasOwnProperty(r))
                continue;
            const route = routes[r];
            route.key = route.key || r || Date.now();
            route.auth = auth;
            if (typeof (route.guard) !== "undefined" &&
                (Array.isArray(route.guard) || typeof (route.guard))) {

                if (route.features && route.features.length) {

                    // platform should have all features to add the routes to it
                    // if the platform doesn't have the feature then we are going to show the 404 page

                    let addRoute = true;
                    route.features.map((item) => {
                        if ( !isFeaturePresent(item) )
                            addRoute = false;
                        return item;
                    });
                    if (!addRoute) {
                        continue;
                    }

                }

                if (route.guard || Array.isArray(route.guard)) {
                    result.push(PrivateRouteWithLayout(route))
                } else {
                    result.push(RouteWithLayout(route))
                }

            } else {
                console.warn("GUARD IS UNDEFINED");
                console.warn("please add guard to route, if it's public then add { guard: false }");
                console.warn("I'm preventing route from the application, thanks for your understanding.");
                console.warn(route);
            }


        }

        setRenderedRoutes(result);

    }, [auth, isFeaturePresent, routes]);

    return (
        <Switch>
            {renderedRoutes}
            {is404 === false ? '' :
                <Route strict={true} exact={true}>
                    <NotFound/>
                </Route>}
        </Switch>
    );
}




