
import axios from './Axios';

const initialState = { titles: []};

const titleConstants = {
    GET_TITLES: 'GET_TITLES',
    GET_TITLES_SUCCESS: 'GET_TITLES_SUCCESS',
    GET_TITLES_FAILURE: 'GET_TITLES_FAILURE',
};

export const titleActions = {
    /* get */
    getTitles: (params) => {
        return (dispatch) => {

            axios.get('/main/person-titles/',{params})
                .then((response) => dispatch(titleActions.getTitlesSuccess(response)))
                .catch((response) => dispatch(titleActions.getTitlesFailure(response)));

            return {
                type: titleConstants.GET_TITLES,
            };
        };
    },

    getTitlesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: titleConstants.GET_TITLES_SUCCESS,
                payload: {
                    titles: response.items
                }
            });
        };
    },

    getTitlesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: titleConstants.GET_TITLES_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function titleReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case titleConstants.GET_TITLES:
            return {
                ...state
            };
        case titleConstants.GET_TITLES_SUCCESS:
            return {
                ...state,
                titles: action.payload.titles
            };
        case titleConstants.GET_TITLES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
