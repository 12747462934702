import axios from './Axios';

const initialState = { industrySectors: []};

const industrySectorConstants = {
    GET_INDUSTRY_SECTORS: 'GET_INDUSTRY_SECTORS',
    GET_INDUSTRY_SECTORS_SUCCESS: 'GET_INDUSTRY_SECTORS_SUCCESS',
    GET_INDUSTRY_SECTORS_FAILURE: 'GET_INDUSTRY_SECTORS_FAILURE',
};

export const industrySectorActions = {
    /* get */
    getIndustrySectors: (params) => {
        // DEPRECATED: please use the function from industrySector__.js
        console.info("this method is obselete, please don't use this method anymore, instead use getIndustries from industrySector__.js");
        console.info("industry.js:13 action getIndustries");
        return (dispatch) => {

            axios.get('/main/industry-sectors/', {params})
                .then((response) => dispatch(industrySectorActions.getIndustrySectorsSuccess(response)))
                .catch((response) => dispatch(industrySectorActions.getIndustrySectorsFailure(response)));

            return {
                type: industrySectorConstants.GET_INDUSTRY_SECTORS,
            };
        };
    },

    getIndustrySectorsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: industrySectorConstants.GET_INDUSTRY_SECTORS_SUCCESS,
                payload: {
                    industrySectors: response.items
                }
            });
        };
    },

    getIndustrySectorsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: industrySectorConstants.GET_INDUSTRY_SECTORS_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function industrySectorReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case industrySectorConstants.GET_INDUSTRY_SECTORS:
            return {
                ...state
            };
        case industrySectorConstants.GET_INDUSTRY_SECTORS_SUCCESS:
            return {
                ...state,
                industrySectors: action.payload.industrySectors
            };
        case industrySectorConstants.GET_INDUSTRY_SECTORS_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
