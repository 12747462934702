import axios from './Axios';

const initialState = { industries: []};

const industryConstants = {
    GET_INDUSTRIES: 'GET_INDUSTRIES',
    GET_INDUSTRIES_SUCCESS: 'GET_INDUSTRIES_SUCCESS',
    GET_INDUSTRIES_FAILURE: 'GET_INDUSTRIES_FAILURE',
};

export const industryActions = {
    /* get */
    getIndustries: (params) => {
        // DEPRECATED: please use the function from industry__.js
        console.info("this method is obselete, please don't use this method anymore, instead use getIndustries from Industry__.js");
        console.info("industry.js:13 action getIndustries");
        return (dispatch) => {

            axios.get('/main/industries/', {params})
                .then((response) => dispatch(industryActions.getIndustriesSuccess(response)))
                .catch((response) => dispatch(industryActions.getIndustriesFailure(response)));

            return {
                type: industryConstants.GET_INDUSTRIES,
            };
        };
    },

    getIndustriesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: industryConstants.GET_INDUSTRIES_SUCCESS,
                payload: {
                    industries: response.items
                }
            });
        };
    },

    getIndustriesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: industryConstants.GET_INDUSTRIES_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function industryReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case industryConstants.GET_INDUSTRIES:
            return {
                ...state
            };
        case industryConstants.GET_INDUSTRIES_SUCCESS:
            return {
                ...state,
                industries: action.payload.industries
            };
        case industryConstants.GET_INDUSTRIES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
