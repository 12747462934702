import axios from './Axios'

const initialState = { addressTypes: [] }

const adminUnlockQuestionnaireConstants = {
	GET_ADDRESS_TYPES: 'GET_ADDRESS_TYPES',
	GET_ADDRESS_TYPES_SUCCESS: 'GET_ADDRESS_TYPES_SUCCESS',
	GET_ADDRESS_TYPES_FAILURE: 'GET_ADDRESS_TYPES_FAILURE',
}

export const adminUnlockQuestionnaireActions = {
	/* get */
	getCompanies: () => {
		return dispatch => {
			return axios
				.get('/main/sme-companies/')
				.then(response => {
					dispatch(adminUnlockQuestionnaireActions.getAddressTypesSuccess(response))
					return response
				})
				.catch(response => dispatch(adminUnlockQuestionnaireActions.getAddressTypesFailure(response)))
		}
	},
	getUnlockRequests: params => {
		const { pending, page, per_page,sme_company_id } = params
		return dispatch => {
			return axios
				.get(`/main/company_request_datas/?pending=${pending}&page=${page}&per_page=${per_page}${sme_company_id?'&sme_company_id='+sme_company_id:''}`)
				.then(response => {
					// dispatch(adminUnlockQuestionnaireActions.getAddressTypesSuccess(response))
					return response
				})
				.catch(response => response)
		}
	},
	getAddressTypesSuccess: response => {
		return dispatch => {
			dispatch({
				type: adminUnlockQuestionnaireConstants.GET_ADDRESS_TYPES_SUCCESS,
				payload: {
					addressTypes: response.items,
				},
			})
		}
	},

	getAddressTypesFailure: response => {
		return dispatch => {
			dispatch({
				type: adminUnlockQuestionnaireConstants.GET_ADDRESS_TYPES_FAILURE,
				payload: { error: response },
			})
		}
	},
}

export function adminUnlockQuestionnaireReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state
	}
}
