import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/profile-complete/',
        'SmeCompleteness',
        initialState,
        ['get']);

export const smeCompletenessConstants = constants;
export const smeCompletenessActions = actions;
export const smeCompletenessReducer = reducer;
