import {Button, Modal, Segment} from "semantic-ui-react";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useTranslation} from "react-i18next";
import {loginReusePreventionActions} from "../store/LoginReusePrevention";
import {useHistory} from "react-router";

const LoginReusePrevention = (props) => {
    const history = useHistory()
    const {t} = useTranslation();
    const {lrp, revokeSession, deleteSession, resetSessionState, auth} = props;

    const showReplacePrompt =
        auth.user
        && lrp?.session
        && lrp?.session?.['loginAllowed'] === false
        && lrp?.session?.['limitReached'] === true
        // && lrp?.starting === true;

    const showRevokedNotice =
        auth.user
        && lrp?.session
        && lrp?.session?.['loginAllowed'] === false
        && lrp?.session?.['revoked'] === true
        && lrp?.starting !== true;

    const onContinue = ()=>{
        const otherSessions = lrp?.session?.sessions;
        if (Array.isArray(otherSessions) && otherSessions.length) {
            for (const session of otherSessions){
                const {id, key} = session
                revokeSession({id, key, recreateSession: true})
            }
        }
    }

    const onRevoked = ()=> {
        // deleteSession(lrp?.session?.yourSession)
        if (auth.user && auth.loggedIn) {
			history.push('/logout')
            resetSessionState();
		}
    }

    return (
        [showReplacePrompt ?
            <Modal
                open
                dimmer={true}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
                style={{ maxWidth: 450 }}
            >
                <Modal.Header>
                    {t("LoginReusePrevention::Another session was found")}
                </Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        {t("LoginReusePrevention::Logged in on another session")}
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                <Button
                    primary
                    type={"button"}
                    onClick={onContinue}
                    content={t("LoginReusePrevention::Continue")}
                />
                </Modal.Actions>
            </Modal> : null,
        showRevokedNotice ?
            <Modal
                open
                dimmer={true}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
                style={{ maxWidth: 450 }}
            >
                <Modal.Header>
                    {t("LoginReusePrevention::This session was replaced")}
                </Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        {t("LoginReusePrevention::You have recently signed in on another browser")}
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                <Button
                    primary
                    type={"button"}
                    onClick={onRevoked}
                    content={t("LoginReusePrevention::Continue")}
                />
                </Modal.Actions>
            </Modal> : null]
    )
}
const mapStateToProps = state => {
	return {
		lrp: state.loginReusePrevention,
        auth: state.auth
	}
}


export default connect(
    state => mapStateToProps(state),
    dispatch => bindActionCreators({
        ...loginReusePreventionActions,
        dispatch
    }, dispatch))(LoginReusePrevention);
