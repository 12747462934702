import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/scm/statistics/',
        'ScmStatistics',
        'ScmStatistics',
        initialState,
        ['get']);

export const scmStatisticsConstants = constants;
export const scmStatisticsActions = actions;
export const scmStatisticsReducer = reducer;
