import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/save-she-compliance-status',
        'SheCompliance',
        'SheCompliances',
        initialState);

export const sheComplianceConstants = constants;
export const sheComplianceActions = actions;
export const sheComplianceReducer = reducer;