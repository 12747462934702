import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/nationalities/',
        'Nationality',
        'Nationalities',
        initialState);

export const nationalityConstants = constants;
export const nationalityActions = actions;
export const nationalityReducer = reducer;
