import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/fetch-pre-audit-questions/',
        'PreAuditQuestion',
        'PreAuditQuestions',
        initialState);

export const preAuditQuestionConstants = constants;
export const preAuditQuestionActions = actions;
export const preAuditQuestionReducer = reducer;
