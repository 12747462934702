import axios from './Axios';

const initialState = {companyContacts: [], loading: false};

const companyContactConstants = {
    GET_COMPANY_CONTACTS: 'GET_COMPANY_CONTACTS',
    GET_COMPANY_CONTACTS_SUCCESS: 'GET_COMPANY_CONTACTS_SUCCESS',
    GET_COMPANY_CONTACTS_FAILURE: 'GET_COMPANY_CONTACTS_FAILURE',

    POST_COMPANY_CONTACTS: 'POST_COMPANY_CONTACTS',
    POST_COMPANY_CONTACTS_SUCCESS: 'POST_COMPANY_CONTACTS_SUCCESS',
    POST_COMPANY_CONTACTS_FAILURE: 'POST_COMPANY_CONTACTS_FAILURE',

    PATCH_COMPANY_CONTACTS: 'PATCH_COMPANY_CONTACTS',
    PATCH_COMPANY_CONTACTS_SUCCESS: 'PATCH_COMPANY_CONTACTS_SUCCESS',
    PATCH_COMPANY_CONTACTS_FAILURE: 'PATCH_COMPANY_CONTACTS_FAILURE',

    DELETE_COMPANY_CONTACTS: 'DELETE_COMPANY_CONTACTS',
    DELETE_COMPANY_CONTACTS_SUCCESS: 'DELETE_COMPANY_CONTACTS_SUCCESS',
    DELETE_COMPANY_CONTACTS_FAILURE: 'DELETE_COMPANY_CONTACTS_FAILURE',
};

export const companyContactActions = {
    /* get */
    getCompanyContacts: (params) => {
        return (dispatch) => {

            dispatch({
                type: companyContactConstants.GET_COMPANY_CONTACTS,
            });

            return axios.get('/main/company-contacts/', {params})
                .then((response) => { dispatch(companyContactActions.getCompanyContactsSuccess(response)); return response; })
                .catch((response) => { dispatch(companyContactActions.getCompanyContactsFailure(response)); return response; });

        };
    },

    getCompanyContactsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyContactConstants.GET_COMPANY_CONTACTS_SUCCESS,
                payload: {
                    companyContacts: response.items
                }
            });
        };
    },

    getCompanyContactsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyContactConstants.GET_COMPANY_CONTACTS_FAILURE,
                payload: {error: response}
            });

        };
    },

    /* post */
    postCompanyContacts: (data) => {
        return (dispatch) => {

            dispatch({
                type: companyContactConstants.POST_COMPANY_CONTACTS,
            })

            return axios.post('/main/company-contacts/', data)
                .then((response) => dispatch(companyContactActions.postCompanyContactsSuccess(response)))
                .catch((response) => dispatch(companyContactActions.postCompanyContactsFailure(response)));
        };
    },

    postCompanyContactsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyContactConstants.POST_COMPANY_CONTACTS_SUCCESS,
                payload: {
                    response
                }
            });
        };
    },

    postCompanyContactsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyContactConstants.POST_COMPANY_CONTACTS_FAILURE,
                payload: {error: response}
            });

        };
    },

    /* patch */
    patchCompanyContacts: (data) => {
        return (dispatch) => {
            dispatch({
                type: companyContactConstants.PATCH_COMPANY_CONTACTS,
            });

            return axios.patch(`/main/company-contacts/${data.id}/`, data)
                .then((response) => dispatch(companyContactActions.patchCompanyContactsSuccess(response)))
                .catch((response) => dispatch(companyContactActions.patchCompanyContactsFailure(response)));;
        };
    },

    patchCompanyContactsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyContactConstants.PATCH_COMPANY_CONTACTS_SUCCESS,
                payload: {
                    response
                }
            });
        };
    },

    patchCompanyContactsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyContactConstants.PATCH_COMPANY_CONTACTS_FAILURE,
                payload: {error: response}
            });

        };
    },

    /* delete */
    deleteCompanyContacts: (id) => {
        return (dispatch) => {

            dispatch({
                type: companyContactConstants.DELETE_COMPANY_CONTACTS,
            });

            return axios.delete(`/main/company-contacts/${id}/`)
                .then((response) => dispatch(companyContactActions.deleteCompanyContactsSuccess(response)))
                .catch((response) => dispatch(companyContactActions.deleteCompanyContactsFailure(response)));;
        };
    },

    deleteCompanyContactsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyContactConstants.DELETE_COMPANY_CONTACTS_SUCCESS,
                payload: {
                    response
                }
            });
        };
    },

    deleteCompanyContactsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyContactConstants.DELETE_COMPANY_CONTACTS_FAILURE,
                payload: {error: response}
            });

        };
    }
};

export function companyContactReducer(state = initialState, action) {
    switch (action.type) {
        /* get */
        case companyContactConstants.GET_COMPANY_CONTACTS:
            return {
                ...state,
                loading: true
            };
        case companyContactConstants.GET_COMPANY_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                companyContacts: action.payload.companyContacts
            };
        case companyContactConstants.GET_COMPANY_CONTACTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        /* post */
        case companyContactConstants.POST_COMPANY_CONTACTS:
            return {
                ...state,
                loading: true
            };
        case companyContactConstants.POST_COMPANY_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.response
            };
        case companyContactConstants.POST_COMPANY_CONTACTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        /* patch */
        case companyContactConstants.PATCH_COMPANY_CONTACTS:
            return {
                ...state,
                loading: true,
            };
        case companyContactConstants.PATCH_COMPANY_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.response
            };
        case companyContactConstants.PATCH_COMPANY_CONTACTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        /* delete */
        case companyContactConstants.DELETE_COMPANY_CONTACTS:
            return {
                ...state,
                loading: true,
            };
        case companyContactConstants.DELETE_COMPANY_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.response
            };
        case companyContactConstants.DELETE_COMPANY_CONTACTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state
    }
}
