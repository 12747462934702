import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/opportunities/',
        'Opportunity',
        'Opportunities',
        initialState);

export const opportunityConstants = constants;
export const opportunityActions = actions;
export const opportunityReducer = reducer;
