import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/technical-accreditation-review/',
        'AccreditationReview',
        'AccreditationReviews',
        initialState);

export const accreditationReviewConstants = constants;
export const accreditationReviewActions = actions;
export const accreditationReviewReducer = reducer;