import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/auth/invite/',
        'Invite',
        'Invites',
        initialState);

export const inviteConstants = constants;
export const inviteActions = actions;
export const inviteReducer = reducer;
