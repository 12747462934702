import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/request_registration/',
        'RegistrationRequest',
        'RegistrationRequests',
        initialState,
        );

export const registrationRequestConstants = constants;
export const registrationRequestActions = actions;
export const registrationRequestReducer = reducer;
