import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/loan_application_funding_items/',
        'ItemToBeFunded',
        'ItemsToBeFunded',
        initialState);

export const itemToBeFundedConstants = constants;
export const itemToBeFundedActions = actions;
export const itemToBeFundedReducer = reducer;