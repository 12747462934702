import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/document-types/',
        'DocumentType',
        'DocumentTypes',
        initialState);

export const documentTypeConstants = constants;
export const documentTypeActions = actions;
export const documentTypeReducer = reducer;
