import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ci from './ci.js';

// configure global one
i18n.options = {
    ...i18n.options,

    fallbackLng: 'en-US',
    // debug: true,

    nsSeparator: ':::',
    keySeparator: '::',

    ns: [
        'translation',
        'validation',
        'error'
    ],

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    }

};

if (!ci.testing) {
    // if not in testing mode use translations from server
    // otherwise just mock it
    i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
        .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
}

i18n.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init();


export default i18n;

