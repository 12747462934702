import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/expired_document_check/',
        'CompanyDocumentExpired',
        'CompanyDocumentsExpired',
        initialState);

export const companyDocumentExpiredConstants = constants;
export const companyDocumentExpiredActions = actions;
export const companyDocumentExpiredReducer = reducer;
