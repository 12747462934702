import { useStore } from "react-redux";

let platform_features = [];

// these functions defined globally to prevent react infinite re-render
const getFeature = (name) => {
    if (platform_features) {
        let item = platform_features.find((s) => s.feature_key === name)
        try {
            return JSON.parse(item.feature_value);
        } catch (e) {
            return item?.feature_value? item.feature_value: undefined;
        }
    }
}

const isFeaturePresent = (name) => {
    if (platform_features) {
        const ft = getFeature(name);
        return typeof (ft) !== 'undefined' && ft !== false;
    }
}

const usePlatformFeatures = () => {

    const store = useStore();

    // console.log(store.getState().platformFeatures.items);

    platform_features = store.getState().platformFeatures?.items || [];

    return {
        isFeaturePresent: isFeaturePresent,
        getFeature: getFeature
    }

};

export default usePlatformFeatures;