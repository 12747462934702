import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

// this platform features depend on the customer
// e.g. zevoli, vanilla, ...
let {constants, actions, reducer} =
    createCrud(
        '/main/platform-features/',
        'PlatformFeature',
        'PlatformFeatures',
        initialState);

export const platformFeaturesConstants = constants;
export const platformFeaturesActions = actions;
export const platformFeaturesReducer = reducer;
