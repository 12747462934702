import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/local-municipalities/',
        'LocalMunicipality',
        'LocalMunicipalities',
        initialState);

export const localMunicipalityConstants = constants;
export const localMunicipalityActions = actions;
export const localMunicipalityReducer = reducer;