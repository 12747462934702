import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/addresses/',
        'CompanyAddress',
        'CompanyAddresses',
        initialState);

export const companyAddressConstants = constants;
export const companyAddressActions = actions;
export const companyAddressReducer = reducer;

