import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/education-level/',
        'EducationLevel',
        'EducationLevels',
        initialState);

export const educationLevelConstants = constants;
export const educationLevelActions = actions;
export const educationLevelReducer = reducer;

