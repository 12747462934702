import axios from './Axios';
import {authActions} from "./auth";

const initialState = {session: {}, starting: false, loading: false }

const LRP_URL= 'https://sessioncontrol1.linkage.co.za/session'
// const LRP_URL = 'http://127.0.0.1:3030/session';

const LoginReusePreventionConstants = {
    GET_EXISTING_SESSION: 'GET_EXISTING_SESSION',
    EXISTING_SESSION_UPDATE: 'EXISTING_SESSION_UPDATE',
    GET_NEW_SESSIION_SUCCESS: 'GET_NEW_SESSIION_SUCCESS',
    INDICATE_STARTING: 'INDICATE_STARTING',
    GET_EXISTING_SESSION_FAILURE: 'GET_EXISTING_SESSION_FAILURE',
    RESET_SESSION_STATE: 'RESET_SESSION_STATE',
    SAVE_RESPONSE: 'SAVE_RESPONSE'
};

const enc = (message) => {
  message = btoa(JSON.stringify(message))
  const a = 'IuvZYrSO7pfsWRdgVnA6hPomHTcqC12EFx0/4JXjw9k8aiK+tLGzMQ5U=NebyDBl3'
  const b = 'pdIjmBZt7RMEc.2564gnOWwFuzJqxCLGyiAQ8fSH9eb1Yk0T_aPoD~hUlr3XVNvKs'
  return message.replace(/[a-z,A-Z,0-9,/,+,=]/gi, $ => b[a.indexOf($)])
}
const dec = (message) => {
  const a = 'IuvZYrSO7pfsWRdgVnA6hPomHTcqC12EFx0/4JXjw9k8aiK+tLGzMQ5U=NebyDBl3'
  const b = 'pdIjmBZt7RMEc.2564gnOWwFuzJqxCLGyiAQ8fSH9eb1Yk0T_aPoD~hUlr3XVNvKs'
  return JSON.parse(atob(message.replace(/[a-z,A-Z,0-9,~,.,_]/gi, $ => a[b.indexOf($)])))
}

// const enc = (message) => {
//     return JSON.stringify(message, null, 2)
// }
// const dec = (message) => {
//     return JSON.parse(message);
// }
const storeLRPSession = (session) => {
    const currentSessionStore = loadLRPSession();
    const id = session.id
    const newItem = {};
    newItem[id] = session;
    const newStore = {...currentSessionStore, ...newItem}
    localStorage.setItem('LRP_SESSION', enc(newStore));
}
const loadLRPSession = (id) => {
    try {
        let data = localStorage.getItem('LRP_SESSION');
        if (!data) data = {}
        else
            data = dec(data);

        if (!id) {
            return data;
        }
        if (id) {
            return data[id];
        }
    } catch (e) {
        console.log("problem loading session", e)
        return undefined;
    }
}

const removeLRPSession = (id) => {
    let data = localStorage.getItem('LRP_SESSION');
    try {
        if (!data) data = {}
        else
            data = dec(data)
        delete data[id]
        const store = {...data}
        localStorage.setItem('LRP_SESSION', enc(store))
    } catch (e) {
        console.error("error when removing LRP session", e);
    }
}

const getFullId = id => {
    const prefix = (window?.location?.hostname ?? 'localhost') + '__';
    if (id.startsWith(prefix)) return id;
    return prefix + id;
}
export const loginReusePreventionActions = {
    startSession: (id) => {
        return (dispatch) => {
            dispatch(loginReusePreventionActions.indicateStarting())
            id = getFullId(id);
            const param = {id};
            const session = loadLRPSession(id);
            param['createSession'] = true;
            if (session) {
                param['key'] = session.key;
            }

            axios.post(LRP_URL, param)
                .then((response) => {
                    if (response['loginAllowed'] === true)
                        return dispatch(loginReusePreventionActions.getNewSessionSuccess(response))
                    else {
                        if (response['limitReached'] === false) {
                            return dispatch(loginReusePreventionActions.startSession(id))
                        }
                        if (response['limitReached'] === true) {
                            return dispatch(loginReusePreventionActions.sessionLimitReached(response))
                        }
                    }
                })
                .catch((response) => dispatch(loginReusePreventionActions.getExistingSessionFailure(response)));

            return {
                type: LoginReusePreventionConstants.GET_EXISTING_SESSION,
                payload: {
                    loading: true
                }
            };
        };
    },
    indicateStarting: () => {
        return (dispatch) => {
            return {
                type: LoginReusePreventionConstants.INDICATE_STARTING
            }
        }
    },
    checkExistingSession: (id, alwaysUpdate = false) => {
        return (dispatch) => {
            const {key} = loadLRPSession(getFullId(id)) ?? {};
            if (key) {
                axios.post(LRP_URL, {id: getFullId(id), key})
                    .then((response) => {
                        const {loginAllowed, limitReached, revoked} = response
                        if (loginAllowed === false && limitReached === false && !revoked) {
                            dispatch(loginReusePreventionActions.startSession(id))
                        } else {
                            if (alwaysUpdate || (loginAllowed === false && limitReached == true))
                            dispatch(loginReusePreventionActions.existingSessionUpdate(response))
                        }
                    })
                    .catch((response) => dispatch(loginReusePreventionActions.getExistingSessionFailure(response)));
            }
            return {
                type: LoginReusePreventionConstants.GET_EXISTING_SESSION,
                payload: {
                    loading: false
                }
            };
        };
    },

    revokeSession: ({id, key, recreateSession = true}) => {
        return (dispatch) => {
            if(id && key) {
               axios.delete(LRP_URL, { params: {id: getFullId(id), key, revoke: true}})
                    .then((response) =>  {
                        if (recreateSession) {
                            dispatch(loginReusePreventionActions.startSession(id))
                        }
                    })
                    .catch((response) => dispatch(loginReusePreventionActions.getExistingSessionFailure(response)));
            }
        }
    },
    deleteSession: ({id, key}) => {
        return (dispatch) => {
            if(id && key) {
               axios.delete(LRP_URL, { params: {id: getFullId(id), key, revoke: false}})
                    .then((response) =>  {

                    })
                    .catch((response) => dispatch(loginReusePreventionActions.getExistingSessionFailure(response)));
            }
        }
    },

    resetSessionState: () => {
        return (dispatch) => {
            dispatch({
                type: LoginReusePreventionConstants.RESET_SESSION_STATE
            })
        }
    },

    sessionLimitReached: (response) => {
        return (dispatch) => {
            dispatch({
                type: LoginReusePreventionConstants.SAVE_RESPONSE,
                payload: {
                    session: response,
                    loading: false
                }
            });
        };
    },

    getNewSessionSuccess: (response) => {
        return (dispatch) => {
            if (response['yourSession'])
                storeLRPSession(response['yourSession'])
            dispatch({
                type: LoginReusePreventionConstants.GET_NEW_SESSIION_SUCCESS,
                payload: {
                    session: response,
                    starting: false,
                    loading: false
                }
            });
        };
    },

    existingSessionUpdate: (response) => {
        return (dispatch) => {
            dispatch({
                type: LoginReusePreventionConstants.EXISTING_SESSION_UPDATE,
                payload: {
                    session: response,
                    loading: false
                }
            });
        };
    },

    getExistingSessionFailure: (response) => {
        return dispatch => {

            dispatch({
                type: LoginReusePreventionConstants.GET_EXISTING_SESSION_FAILURE,
                payload: {
                    error: response,
                    loading: false
                }
            });

        };
    },
};

export function LoginReusePreventionReducer(state = initialState, action) {
    // console.log(action.type)
    switch (action.type) {
        /* get */
        case LoginReusePreventionConstants.GET_EXISTING_SESSION:
            return {
                state,
                loading: action.payload.loading
            };
        case LoginReusePreventionConstants.INDICATE_STARTING:
            return {
                state,
                starting: true
            };
        case LoginReusePreventionConstants.EXISTING_SESSION_UPDATE:
            return {
                ...state,
                starting: false,
                session: action.payload.session,
                loading: action.payload.loading
            };
        case LoginReusePreventionConstants.GET_NEW_SESSIION_SUCCESS:
            return {
                ...state,
                starting: false,
                session: action.payload.session,
                loading: action.payload.loading
            };
        case LoginReusePreventionConstants.SAVE_RESPONSE:
            return {
                ...state,
                session: action.payload.session,
                loading: action.payload.loading
            };
        case LoginReusePreventionConstants.GET_EXISTING_SESSION_FAILURE:
            return {
                ...state,
                session: {},
                error: action.payload.error,
                loading: action.payload.loading
            };
        case LoginReusePreventionConstants.RESET_SESSION_STATE:
            return {
                ...initialState
            };
        default:
            return state
    }
}
