import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/collaborator-search/',
        'PotentialCollaborator',
        'PotentialCollaborators',
        initialState);

export const potentialCollaboratorConstants = constants;
export const potentialCollaboratorActions = actions;
export const potentialCollaboratorReducer = reducer;
