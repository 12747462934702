import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/scm-companies/',
        'ScmCompany',
        'ScmCompanies',
        initialState);

export const scmCompanyConstants = constants;
export const scmCompanyActions = actions;
export const scmCompanyReducer = reducer;