import React, {Suspense} from 'react'
import 'semantic-ui-less/semantic.less'
// import './styles/index.css'
import '../src/styles/theme/vanilla.less'
import '../src/styles/theme/zevoli.less'

import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import store, { browserHistory } from './store/_configureStore'
import App from './components/layout/App'
import { ConnectedRouter } from 'connected-react-router'

import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

// import registerServiceWroker from './components/common/registerServiceWorker'

import i18n from './i18n';
import AppLoader from "./components/Loader";
import {locale} from "moment";
import * as Sentry from "@sentry/react";
import { stopReportingRuntimeErrors } from "react-error-overlay"
const rootElement = document.getElementById('root');

document.body.classList.add(window.location.href.includes('vanilla')|| window.location.href.includes('telkom') ? 'vanilla' : 'zevoli')

Sentry.init({
  dsn: "https://81eea16aa66b45cbb15e0ea94574786d@o4504954873708544.ingest.sentry.io/4504972646612992",
  environment: window.location.host || 'unknown',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.001, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

//stop sentry when running on localhost
if (window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1") {
    stopReportingRuntimeErrors();
    Sentry.init({});
}


window.sendSentryMessage = (message) => {
    return Sentry.captureMessage(message);
}

ReactDOM.render(

    <Provider store={store}>
        <ConnectedRouter history={browserHistory} >
            <SemanticToastContainer />
            {/*<React.StrictMode>*/}
            <Suspense fallback={<AppLoader/>}>
                <App i18n={i18n} />
            </Suspense>
            {/*</React.StrictMode>*/}
        </ConnectedRouter>
    </Provider>
    , rootElement);

// registerServiceWroker();

