import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/sme-company-growth-plans/',
        'SmeCompanyGrowthPlan',
        'SmeCompanyGrowthPlans',
        initialState);

export const smeCompanyGrowthPlanConstants = constants;
export const smeCompanyGrowthPlanActions = actions;
export const smeCompanyGrowthPlanReducer = reducer;
