import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/industry-sectors/',
        'IndustrySector',
        'IndustrySectors',
        initialState);

export const industrySectorConstants = constants;
export const industrySectorActions = actions;
export const industrySectorReducer_ = reducer;
