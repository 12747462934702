import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/industries/',
        'Industry',
        'Industries',
        initialState);

export const industryConstants = constants;
export const industryActions = actions;
export const industryReducer_ = reducer;
