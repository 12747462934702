import axios from './Axios';

const initialState = { loading: false };

const smmeCSDReportEmailConstants = {
    POST_SMME_CSD_EMAIL: 'POST_SMME_CSD_EMAIL',
    POST_SMME_CSD_EMAIL_SUCCESS: 'POST_SMME_CSD_EMAIL_SUCCESS',
    POST_SMME_CSD_EMAIL_FAILURE: 'POST_SMME_CSD_EMAIL_FAILURE',
};

export const smmeCSDReportEmailActions = {
    /* post */
    postSmmeCSDEmail: (data) => {
        return (dispatch) => {
			dispatch({
				type: smmeCSDReportEmailConstants.POST_SMME_CSD_EMAIL,
			})
            return axios.post('/main/send-sme-csd-report-email/', data)
                .then((response) => {
                    dispatch(smmeCSDReportEmailActions.postSmmeCSDEmailSuccess(response))
                    return response
                })
                .catch((response) => {
                    dispatch(smmeCSDReportEmailActions.postSmmeCSDEmailFailure(response))
                    throw response
                });
        };
    },

    postSmmeCSDEmailSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: smmeCSDReportEmailConstants.POST_SMME_CSD_EMAIL_SUCCESS,
                payload: response
            });
        };
    },

    postSmmeCSDEmailFailure: (response) => {
        return dispatch => {
            dispatch({
                type: smmeCSDReportEmailConstants.POST_SMME_CSD_EMAIL_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function smmeCSDReportEmailReducer(state= initialState, action) {
    switch (action.type) {
        /* post */
        case smmeCSDReportEmailConstants.POST_SMME_CSD_EMAIL:
            return {
                ...state,
                loading: true
            };
        case smmeCSDReportEmailConstants.POST_SMME_CSD_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case smmeCSDReportEmailConstants.POST_SMME_CSD_EMAIL_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            };
        default:
            return state
    }
}
