import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/rankings/',
        'Ranking',
        'Rankings',
        initialState);

export const rankingsConstants = constants;
export const rankingsActions = actions;
export const rankingsReducer = reducer;
