import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/admin/statistics/',
        'AdminStatistics',
        'AdminStatistics',
        initialState,
        ['get']);

export const adminStatisticsConstants = constants;
export const adminStatisticsActions = actions;
export const adminStatisticsReducer = reducer;
