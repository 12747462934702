import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/fetch-pre-audit-qualifier',
        'PreAuditQualifier',
        'PreAuditQualifiers',
        initialState);

export const preAuditQualifierConstants = constants;
export const preAuditQualifierActions = actions;
export const preAuditQualifierReducer = reducer;
