import axios from './Axios'

const initialState = { loading: false }

const opportunitiesInterestsConstants = {
	SHOW_INTEREST: 'SHOW_INTEREST',
	SHOW_INTEREST_SUCCESS: 'SHOW_INTEREST_SUCCESS',
	SHOW_INTEREST_FAILURE: 'SHOW_INTEREST_FAILURE',
}

export const opportunitiesInterestsActions = {
	onShowInterest: (params) => {
		return dispatch => {
			dispatch({ type: opportunitiesInterestsConstants.SHOW_INTEREST })
			axios
				.post('/opportunity-applications/',params)
				.then(response => dispatch({ type: opportunitiesInterestsConstants.SHOW_INTEREST_SUCCESS, payload: response }))
				.catch(response => dispatch({ type: opportunitiesInterestsConstants.SHOW_INTEREST_FAILURE, payload: response }))
		}
	},
}

export function opportunitiesInterestsReducer(state = initialState, action) {
	switch (action.type) {
		case opportunitiesInterestsConstants.SHOW_INTEREST:
			return { ...state, loading: true }
		case opportunitiesInterestsConstants.SHOW_INTEREST_SUCCESS:
			return { ...state, loading: false }
		case opportunitiesInterestsConstants.SHOW_INTEREST_FAILURE:
			return { ...state, loading: false }
		default:
			return state
	}
}
