import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/opportunity-collaborators/',
        'OpportunityCollaborators',
        'OpportunityCollaborator',
        initialState);

export const opportunityCollaboratorsConstants = constants;
export const opportunityCollaboratorsActions = actions;
export const opportunityCollaboratorsReducer = reducer;
