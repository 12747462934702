import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/sme-companies/',
        'SmeCompany',
        'SmeCompanies',
        initialState);

export const smeCompanyConstants = constants;
export const smeCompanyActions = actions;
export const smeCompanyReducer = reducer;