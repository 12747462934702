import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/auth/re-invite/',
        'ReInvite',
        'ReInvites',
        initialState,
        ['post']);

export const reInviteConstants = constants;
export const reInviteActions = actions;
export const reInviteReducer = reducer;
