import {createCrud} from "./CRUD";

const initialState = { items: [], item: {} };

let {constants, actions, reducer} =
    createCrud(
        '/main/sme-dev-plan/',
        'SmeDevPlan',
        'SmeDevPlans',
        initialState);

export const smeDevPlanConstants = constants;
export const smeDevPlanActions = actions;
export const smeDevPlanReducer = reducer;
