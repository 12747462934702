import axios from './Axios';

const initialState = { companyTypes: []};

const companyTypeConstants = {
    GET_COMPANY_TYPES: 'GET_COMPANY_TYPES',
    GET_COMPANY_TYPES_SUCCESS: 'GET_COMPANY_TYPES_SUCCESS',
    GET_COMPANY_TYPES_FAILURE: 'GET_COMPANY_TYPES_FAILURE',
};

export const companyTypeActions = {
    /* get */
    getCompanyTypes: (params) => {
        return (dispatch) => {

            axios.get('/main/company-types/', {params})
                .then((response) => dispatch(companyTypeActions.getCompanyTypesSuccess(response)))
                .catch((response) => dispatch(companyTypeActions.getCompanyTypesFailure(response)));

            return {
                type: companyTypeConstants.GET_COMPANY_TYPES,
            };
        };
    },

    getCompanyTypesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyTypeConstants.GET_COMPANY_TYPES_SUCCESS,
                payload: {
                    companyTypes: response.items
                }
            });
        };
    },

    getCompanyTypesFailure: (response, returnUrl) => {
        return dispatch => {

            dispatch({
                type: companyTypeConstants.GET_COMPANY_TYPES_FAILURE,
                payload: {error: response}
            });

        };
    },
};

export function companyTypeReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case companyTypeConstants.GET_COMPANY_TYPES:
            return {
                ...state
            };
        case companyTypeConstants.GET_COMPANY_TYPES_SUCCESS:
            return {
                ...state,
                companyTypes: action.payload.companyTypes
            };
        case companyTypeConstants.GET_COMPANY_TYPES_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default:
            return state
    }
}
