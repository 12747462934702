import axios from './Axios';

const initialState = { items: []};

const complianceReferenceConstants = {
    GET_COMPLIANCEREFERENCE: 'GET_COMPLIANCEREFERENCE',
    GET_COMPLIANCEREFERENCE_SUCCESS: 'GET_COMPLIANCEREFERENCE_SUCCESS',
    GET_COMPLIANCEREFERENCE_FAILURE: 'GET_COMPLIANCEREFERENCE_FAILURE',

    POST_COMPLIANCEREFERENCE: 'POST_COMPLIANCEREFERENCE',
    POST_COMPLIANCEREFERENCE_SUCCESS: 'POST_COMPLIANCEREFERENCE_SUCCESS',
    POST_COMPLIANCEREFERENCE_FAILURE: 'POST_COMPLIANCEREFERENCE_FAILURE',

    PATCH_COMPLIANCEREFERENCE: 'PATCH_COMPLIANCEREFERENCE',
    PATCH_COMPLIANCEREFERENCE_SUCCESS: 'PATCH_COMPLIANCEREFERENCE_SUCCESS',
    PATCH_COMPLIANCEREFERENCE_FAILURE: 'PATCH_COMPLIANCEREFERENCE_FAILURE',

    DELETE_COMPLIANCEREFERENCE: 'DELETE_COMPLIANCEREFERENCE',
    DELETE_COMPLIANCEREFERENCE_SUCCESS: 'DELETE_COMPLIANCEREFERENCE_SUCCESS',
    DELETE_COMPLIANCEREFERENCE_FAILURE: 'DELETE_COMPLIANCEREFERENCE_FAILURE',
};

export const complianceReferenceActions = {
    /* get */
    getComplianceReferences: (sme_company_id) => {
        return (dispatch) => {

            dispatch({
                type: complianceReferenceConstants.GET_COMPLIANCEREFERENCE,
                payload: {
                    loading: true
                }
            });

            // at the moment a company can have only one compliance reference
            return axios.get('/main/compliance-references/', { params: { sme_company_id } })
                .then((response) => { dispatch(complianceReferenceActions.getComplianceReferencesSuccess(response)); return response; })
                .catch((response) => { dispatch(complianceReferenceActions.getComplianceReferencesFailure(response)); return response; });

        };
    },

    getComplianceReferencesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: complianceReferenceConstants.GET_COMPLIANCEREFERENCE_SUCCESS,
                payload: {
                    items: response.items,
                    loading: false
                }
            });
        };
    },

    getComplianceReferencesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: complianceReferenceConstants.GET_COMPLIANCEREFERENCE_FAILURE,
                payload: {
                    items: [],
                    error: response,
                    loading: false
                }
            });

        };
    },

    /* post */
    postComplianceReferences: (data) => {
        return (dispatch) => {

            dispatch ({
                type: complianceReferenceConstants.POST_COMPLIANCEREFERENCE,
                payload: {
                    loading: true,
                }
            });

            return axios.post('/main/compliance-references/', data)
                .then((response) => { dispatch(complianceReferenceActions.postComplianceReferencesSuccess(response)); return response; })
                .catch((response) => { dispatch(complianceReferenceActions.postComplianceReferencesFailure(response)); return response; });

        };
    },

    postComplianceReferencesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: complianceReferenceConstants.POST_COMPLIANCEREFERENCE_SUCCESS,
                payload: {
                    loading: false
                }
            });
        };
    },

    postComplianceReferencesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: complianceReferenceConstants.POST_COMPLIANCEREFERENCE_FAILURE,
                payload: {
                    loading: false,
                    errors: response
                }
            });

        };
    },

    /* delete */
    patchComplianceReferences: (data) => {
        return (dispatch) => {

            dispatch ({
                type: complianceReferenceConstants.PATCH_COMPLIANCEREFERENCE,
                payload: {
                    loading: true
                },
            });

            return axios.patch('/main/compliance-references/' + data.id, data)
                .then((response) => { dispatch(complianceReferenceActions.patchComplianceReferencesSuccess(response)); return response; })
                .catch((response) => { dispatch(complianceReferenceActions.patchComplianceReferencesFailure(response)); return response; });

        };
    },

    patchComplianceReferencesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: complianceReferenceConstants.PATCH_COMPLIANCEREFERENCE_SUCCESS,
                payload: {
                    loading: false
                }
            });
        };
    },

    patchComplianceReferencesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: complianceReferenceConstants.PATCH_COMPLIANCEREFERENCE_FAILURE,
                payload: {
                    loading: false,
                    errors: response
                }
            });

        };
    },

    /* delete */
    deleteComplianceReferences: (id) => {
        return (dispatch) => {

            dispatch ({
                type: complianceReferenceConstants.DELETE_COMPLIANCEREFERENCE,
                loading: true,
            });

            return axios.delete('/main/compliance-references/' + id)
                .then((response) => { dispatch(complianceReferenceActions.deleteComplianceReferencesSuccess(response)); return response; })
                .catch((response) => { dispatch(complianceReferenceActions.deleteComplianceReferencesFailure(response)); return response; });

        };
    },

    deleteComplianceReferencesSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: complianceReferenceConstants.DELETE_COMPLIANCEREFERENCE_SUCCESS,
                payload: {
                    loading: false
                }
            });
        };
    },

    deleteComplianceReferencesFailure: (response) => {
        return dispatch => {

            dispatch({
                type: complianceReferenceConstants.DELETE_COMPLIANCEREFERENCE_FAILURE,
                payload: {
                    loading: false,
                    errors: response
                }
            });

        };
    },

};

export function complianceReferenceReducer(state= initialState, action) {
    switch (action.type) {
        /* get */
        case complianceReferenceConstants.GET_COMPLIANCEREFERENCE:
            return {
                ...state,
                loading: action.payload.loading
            };
        case complianceReferenceConstants.GET_COMPLIANCEREFERENCE_SUCCESS:
            return {
                ...state,
                items: action.payload.items,
                loading: action.payload.loading
            };
        case complianceReferenceConstants.GET_COMPLIANCEREFERENCE_FAILURE:
            return {
                ...state,
                items: [],
                error: action.payload.error,
                loading: action.payload.loading
            };
        case complianceReferenceConstants.POST_COMPLIANCEREFERENCE:
            return {
                ...state,
                loading: action.payload.loading,
                errors: {},
            };
        case complianceReferenceConstants.POST_COMPLIANCEREFERENCE_SUCCESS:
            return {
                ...state,
                loading: action.payload.loading,
                error: {},
            };
        case complianceReferenceConstants.POST_COMPLIANCEREFERENCE_FAILURE:
            return {
                ...state,
                loading: action.payload.loading,
            };
        case complianceReferenceConstants.PATCH_COMPLIANCEREFERENCE:
            return {
                ...state,
                loading: action.payload.loading,
                errors: {},
            };
        case complianceReferenceConstants.PATCH_COMPLIANCEREFERENCE_SUCCESS:
            return {
                ...state,
                loading: action.payload.loading,
                error: {},
            };
        case complianceReferenceConstants.PATCH_COMPLIANCEREFERENCE_FAILURE:
            return {
                ...state,
                loading: action.payload.loading,
            };
        case complianceReferenceConstants.DELETE_COMPLIANCEREFERENCE:
            return {
                ...state,
                loading: action.payload.loading,
                errors: {},
            };
        case complianceReferenceConstants.DELETE_COMPLIANCEREFERENCE_SUCCESS:
            return {
                ...state,
                loading: action.payload.loading,
                error: {},
            };
        case complianceReferenceConstants.DELETE_COMPLIANCEREFERENCE_FAILURE:
            return {
                ...state,
                loading: action.payload.loading,
            };

        default:
            return state
    }
}
