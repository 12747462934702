import axios from './Axios';

const initialState = {items: [], loading: false};

const companyOwnershipConstants = {
    GET_COMPANY_OWNERSHIPS: 'GET_COMPANY_OWNERSHIPS',
    GET_COMPANY_OWNERSHIPS_SUCCESS: 'GET_COMPANY_OWNERSHIPS_SUCCESS',
    GET_COMPANY_OWNERSHIPS_FAILURE: 'GET_COMPANY_OWNERSHIPS_FAILURE',

    POST_COMPANY_OWNERSHIPS: 'POST_COMPANY_OWNERSHIPS',
    POST_COMPANY_OWNERSHIPS_SUCCESS: 'POST_COMPANY_OWNERSHIPS_SUCCESS',
    POST_COMPANY_OWNERSHIPS_FAILURE: 'POST_COMPANY_OWNERSHIPS_FAILURE',

    PATCH_COMPANY_OWNERSHIPS: 'PATCH_COMPANY_OWNERSHIPS',
    PATCH_COMPANY_OWNERSHIPS_SUCCESS: 'PATCH_COMPANY_OWNERSHIPS_SUCCESS',
    PATCH_COMPANY_OWNERSHIPS_FAILURE: 'PATCH_COMPANY_OWNERSHIPS_FAILURE',

    DELETE_COMPANY_OWNERSHIPS: 'DELETE_COMPANY_OWNERSHIPS',
    DELETE_COMPANY_OWNERSHIPS_SUCCESS: 'DELETE_COMPANY_OWNERSHIPS_SUCCESS',
    DELETE_COMPANY_OWNERSHIPS_FAILURE: 'DELETE_COMPANY_OWNERSHIPS_FAILURE',
};

export const companyOwnershipActions = {
    /* get */
    getCompanyOwnerships: (params) => {
        return (dispatch) => {

            dispatch({
                type: companyOwnershipConstants.GET_COMPANY_OWNERSHIPS,
            });

            return axios.get('/main/company-bee-ownership-types/', {params})
                .then((response) => { dispatch(companyOwnershipActions.getCompanyOwnershipsSuccess(response)); return response; })
                .catch((response) => { dispatch(companyOwnershipActions.getCompanyOwnershipsFailure(response)); return response; });

        };
    },

    getCompanyOwnershipsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyOwnershipConstants.GET_COMPANY_OWNERSHIPS_SUCCESS,
                payload: {
                    items: response.items
                }
            });
        };
    },

    getCompanyOwnershipsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyOwnershipConstants.GET_COMPANY_OWNERSHIPS_FAILURE,
                payload: {error: response}
            });

        };
    },

    /* post */
    postCompanyOwnerships: (data) => {
        return (dispatch) => {

            dispatch({
                type: companyOwnershipConstants.POST_COMPANY_OWNERSHIPS,
            });

            return axios.post('/main/company-bee-ownership-types/', data)
                .then((response) => { dispatch(companyOwnershipActions.postCompanyOwnershipsSuccess(response)); return response; })
                .catch((response) => { dispatch(companyOwnershipActions.postCompanyOwnershipsFailure(response)); return response; });

        };
    },

    postCompanyOwnershipsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyOwnershipConstants.POST_COMPANY_OWNERSHIPS_SUCCESS,
                payload: {
                    response
                }
            });
        };
    },

    postCompanyOwnershipsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyOwnershipConstants.POST_COMPANY_OWNERSHIPS_FAILURE,
                payload: {error: response}
            });

        };
    },

    /* patch */
    patchCompanyOwnerships: (data) => {
        return (dispatch) => {

            dispatch({
                type: companyOwnershipConstants.PATCH_COMPANY_OWNERSHIPS,
            });

            return axios.patch(`/main/company-bee-ownership-types/${data.id}/`, data)
                .then((response) => { dispatch(companyOwnershipActions.patchCompanyOwnershipsSuccess(response)); return response; })
                .catch((response) => { dispatch(companyOwnershipActions.patchCompanyOwnershipsFailure(response)); return response; });

        };
    },

    patchCompanyOwnershipsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyOwnershipConstants.PATCH_COMPANY_OWNERSHIPS_SUCCESS,
                payload: {
                    response
                }
            });
        };
    },

    patchCompanyOwnershipsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyOwnershipConstants.PATCH_COMPANY_OWNERSHIPS_FAILURE,
                payload: {error: response}
            });

        };
    },

    /* delete */
    deleteCompanyOwnerships: (id) => {
        return (dispatch) => {

            axios.delete(`/main/company-bee-ownership-types/${id}/`)
                .then((response) => dispatch(companyOwnershipActions.deleteCompanyOwnershipsSuccess(response)))
                .catch((response) => dispatch(companyOwnershipActions.deleteCompanyOwnershipsFailure(response)));

            return {
                type: companyOwnershipConstants.DELETE_COMPANY_OWNERSHIPS,
            };
        };
    },

    deleteCompanyOwnershipsSuccess: (response) => {
        return (dispatch) => {
            dispatch({
                type: companyOwnershipConstants.DELETE_COMPANY_OWNERSHIPS_SUCCESS,
                payload: {
                    response
                }
            });
        };
    },

    deleteCompanyOwnershipsFailure: (response) => {
        return dispatch => {

            dispatch({
                type: companyOwnershipConstants.DELETE_COMPANY_OWNERSHIPS_FAILURE,
                payload: {error: response}
            });

        };
    }
};

export function companyOwnershipReducer(state = initialState, action) {
    switch (action.type) {
        /* get */
        case companyOwnershipConstants.GET_COMPANY_OWNERSHIPS:
            return {
                ...state,
                loading: true
            };
        case companyOwnershipConstants.GET_COMPANY_OWNERSHIPS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.items
            };
        case companyOwnershipConstants.GET_COMPANY_OWNERSHIPS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        /* post */
        case companyOwnershipConstants.POST_COMPANY_OWNERSHIPS:
            return {
                ...state,
                loading: true
            };
        case companyOwnershipConstants.POST_COMPANY_OWNERSHIPS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.response
            };
        case companyOwnershipConstants.POST_COMPANY_OWNERSHIPS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        /* patch */
        case companyOwnershipConstants.PATCH_COMPANY_OWNERSHIPS:
            return {
                ...state,
                loading: true,
            };
        case companyOwnershipConstants.PATCH_COMPANY_OWNERSHIPS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.response
            };
        case companyOwnershipConstants.PATCH_COMPANY_OWNERSHIPS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        /* delete */
        case companyOwnershipConstants.DELETE_COMPANY_OWNERSHIPS:
            return {
                ...state,
                loading: true,
            };
        case companyOwnershipConstants.DELETE_COMPANY_OWNERSHIPS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.response
            };
        case companyOwnershipConstants.DELETE_COMPANY_OWNERSHIPS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        default:
            return state
    }
}
